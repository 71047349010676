import React, { useState, useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import http from '../../services/http';
import { Link, useNavigate } from "react-router-dom";
import userData from '../../context/user';
import userService from '../../services/data/user.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Toast } from 'primereact/toast';
import { InputMask } from "primereact/inputmask";
import { useMemo } from 'react';
import tokenService from '../../services/token.service';
import domainData from '../../context/domain';
import { Button } from 'primereact/button';
import axios from "axios";

// Função para obter o valor de um cookie pelo seu nome
function getCookie(name) {
    var cookieArr = document.cookie.split(";");

    // Olhar através de cada par chave=valor
    for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");

        /* Remova espaços iniciais do nome do cookie, e compare-o com o nome dado */
        if (name == cookiePair[0].trim()) {
            // Decodifique o valor do cookie e retorne
            return decodeURIComponent(cookiePair[1]);
        }
    }
    // Retorna null se o cookie não for encontrado
    return null;
}

export default function Login() {

    const { updateUser } = userData()
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [isVisible, setVisible] = useState(false);
    const [value, setValue] = useState('Email'); // login email ou CPF
    const [next, setNext] = useState(null)
    const { domain } = domainData()

    const navigate = useNavigate();
    const toast = useRef(null);

    const [loading, setLoading] = useState(false);

    const toggle = () => {
        setVisible(!isVisible);
    };

    const loginAzure = () => {
        const url = new URL(window.location.href);
        const tenant = url.host.split('.').at(0)
        if (tenant !== 'hcm' && tenant !== 'hcm-hml' ) {
            document.cookie = "tenant=" + tenant + "; path=/; domain=.hcm.jobconvo.com";
        }
        const baseURL = `${process.env.REACT_APP_BASE_URL}auth/azure/login/`
        window.location.href = baseURL
    };


    useEffect(() => {

        if (window.location.search.includes("?next=")) {
            setNext(window.location.search.substring("?next="))
        }

        let url = new URL(window.location.href);
        const token = url.searchParams.get('token')
        const auth_sso = url.searchParams.get('auth_sso')

        if (auth_sso && token != undefined) {
            const baseURL = `${process.env.REACT_APP_BASE_URL}login/token/refresh/`
            const data = {
                refresh: token
            }
            axios.post(baseURL, data).then((response) => {

                let tenant = getCookie("tenant");

                if (tenant == null) {
                    tenant = ''
                } else {
                    tenant = `${tenant}.`
                }
                let href = new URL(window.location.href);
                if (href.host.split('.').at(0) !== 'hcm' && href.host.split('.').at(0) !== 'hcm-hml') {

                    tokenService.updateLocalAccessToken(token, response.data.access);
                    updateUser()
                    setIsLoggedIn(true)

                    window.location.href = url.protocol + '//' + url.host + `/home/` + window.location.search;
                } else {
                    url.hostname = tenant + url.hostname;

                    window.location.href = url.protocol + '//' + url.host +`?auth_sso=success&token=${token}`;
                }

            }).catch((error) => {
                throw error
            });
        }

        userService.getUser().then(() => {
            setIsLoggedIn(true)
        }).catch((error) => {
            setIsLoggedIn(false)
        })

    }, []);

    useMemo(() => {
        if (isLoggedIn) {
            if (next) {
                return navigate(`/home/${next}`)
            }
            return navigate('/home/')
        }
    }, [isLoggedIn])

    const load = () => {
        setLoading(true);
        formik.submitForm()
    };

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',

        },
        validationSchema: Yup.object({
            //email: Yup.string().email("Email inválido"),
        }),
        onSubmit: values => {
            var data = {
                username: values.email,
                password: values.password
            }

            http.post('api/login/token/', data).then( (response) => {

                if (response.data.access) {
                    tokenService.setUser(response.data);

                    updateUser()
                    setIsLoggedIn(true)
                }

                if (response.data['detail']) {
                    toast.current.show({ severity: 'warn', summary: 'Ops', detail: response.data['detail'], life: 4000 });
                }

            }).catch((error) => {
                toast.current.show({ severity: 'warn', summary: 'Ops', detail: JSON.stringify(error.response.data), life: 4000 });
            }).finally(() => {
                if (loading) {
                    setLoading(false)
                }
            })

        }
    });

    return (

        <div className='container-fluid'>

            {

                domain && domain.banner ?
                    <img className='px-0 w-100 fullscreen-background' src={domain.banner} />
                    : null

            }

            <div className="row">

                <Toast ref={toast} position="top-right" />

                <div className="col-lg-4 offset-lg-4 col-md-5 offset-md-3 mt-5">

                    {
                        isLoggedIn ?
                            <div className='text-center'>
                                <p className='lead'>Você está logado</p>
                                <Link className="btn btn-outline-success btn-rounded" to='/home/'>Ir para Home</Link>
                            </div>
                            :
                            <form className="formMarginBottom border shadow bg-white rounded p-3 mt-5" onSubmit={formik.handleSubmit}>
                                <table className='table'>
                                    <tbody>
                                        <tr>

                                            {
                                                domain && domain.logo ?
                                                    <>
                                                        <td className="align-middle" width={50}>
                                                            <img src={`${domain.logo}`} alt="logo" className="p-0 img-thumbnail img-fluid rounded-circle border-0" width="50" />
                                                        </td>
                                                    </>
                                                    : null
                                            }
                                            <td className="align-middle">
                                                {
                                                    domain ?
                                                        <h1 className='mb-0'>{domain.name}</h1>
                                                        :
                                                        <h1 className='mb-0'>Login</h1>
                                                }

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div className='mb-2'>
                                    <div>
                                        <label className="form-label fw-bold">Fazer login usando</label>
                                    </div>
                                    <div className={`form-check form-check-inline selectRadioGroup ${value === 'Email' ? 'active' : null}`}>
                                        <input name='options' className="form-check-input" type="radio" checked={value === 'Email' ? true : false} onChange={(e) => setValue('Email')} />
                                        <label className='form-check-label'>Email</label>
                                    </div>
                                    <div className={`form-check form-check-inline selectRadioGroup ${value === 'CPF' ? 'active' : null}`}>
                                        <input name='options' className="form-check-input" type="radio" onChange={(e) => setValue('CPF')} />
                                        <label className='form-check-label'>CPF</label>
                                    </div>
                        

                                </div>

                                <div className="">
                                    {
                                        value === 'Email' ?
                                            <>
                                                <label className="form-label fw-bold">Email</label>
                                                <input name="email" type="email" className="form-control" required {...formik.getFieldProps('email')} />
                                            </>
                                            :
                                            <>
                                                <label className="form-label fw-bold">CPF</label>
                                                <InputMask className='form-control' style={{ 'padding': '0.37rem 0.75rem' }} value={formik.values.email} onChange={(e) => formik.setFieldValue('email', e.target.value)} mask="999.999.999-99" placeholder="999.999.999-99" />
                                            </>
                                    }
                                </div>
                                <div className=" mt-2">
                                    <label className="form-label fw-bold">Senha</label>
                                    <div className='input-group'>
                                        <input name="password" type={!isVisible ? "password" : "text"} className="form-control" required {...formik.getFieldProps('password')} />
                                        <span onClick={toggle} className='btn bg-body border-start-0' style={{ border: '1px solid #ced4da' }}>
                                            <FontAwesomeIcon className='text-green font095' icon={regular('eye')} />
                                        </span>
                                    </div>
                                </div>
                                <div className="d-grid mx-auto btnMargin">
                                    <Button rounded label="Login" type='submit' icon="pi pi-check" loading={loading} onClick={load} />
                                </div>
                                <p className="text-end btnMargin font095">
                                    Esqueceu a senha? <a className="text-green" href="/recover/">Clique Aqui</a>
                                </p>

                                <div className=" mt-2">
                                    <div style={{ 'width': '100%' }}  className={`form-check form-check-inline selectRadioGroup text-center`} onClick={loginAzure}>
                                        <img src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='21'%3E%3Cpath fill='%23f25022' d='M1 1h9v9H1z'/%3E%3Cpath fill='%2300a4ef' d='M1 11h9v9H1z'/%3E%3Cpath fill='%237fba00' d='M11 1h9v9h-9z'/%3E%3Cpath fill='%23ffb900' d='M11 11h9v9h-9z'/%3E%3C/svg%3E"></img>
                                        <span className='form-check-label' style={{ 'marginLeft': '2rem' }} >Microsoft Account</span>
                                    </div>
                                </div>
                            </form>
                    }
                </div>
            </div>

        </div>

    );
};