import React, {useState, useEffect, useRef, useMemo} from 'react';
import ProtectedRoute from '../../routes/protected';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Toast } from 'primereact/toast';
import { useParams, useNavigate } from 'react-router-dom';
import careerService from '../../services/data/career.service';
import SkeletonSummary from './skeletonSummary';
import CareerHeader from './careerHeader';
import CareerMenu from './careerMenu';
import performanceService from '../../services/data/performance.service';
import moment from "moment";
import 'moment/locale/pt-br'
import userData from '../../context/user';
import CareerPerformancePDIDetail from './careerPerformancePDIDetail';


export default function CareerPerformancePDI() {

    const [pageLoad, setPageLoad] = useState(true)
    const toast = useRef(null);
    const { id } = useParams()
    const [userEmployee, setUserEmployee] = useState('')
    const [performanceList, setPerformanceList] = useState([])
    const [page, setPage] = useState(1)
    const {user} = userData()
    const [showPerformanceTabs, setShowPerformanceTabs] = useState(false)
    const navigate = useNavigate();

    // PDI detail
    const [performanceTemplate, setPerformanceTemplate] = useState(null)
    const [items, setItems] = useState([]) // list of steps but get always the first
    const [stepDetail, setStepDetail] = useState(null)

    useEffect(() => {

        getData()
        
        
    }, [])

    function getData() {

        let data = {
            company_id: localStorage.getItem("companyId"),
            id : id
        }

        careerService.getEmployeeSummary(data).then((response) => {    
            setUserEmployee(response)
        }).catch((error) => {
            console.log(error.response)
        }).finally(() => {
            setPageLoad(false)
        })
    
    }

    useMemo(() => {
        if (userEmployee) {
            listPerformances()
        }
    }, [userEmployee, page])

    function listPerformances() {
        let data = {
            company_id: localStorage.getItem("companyId"),
            employees: userEmployee.id,
            assessment_type: Array('PD')
        }

        performanceService.listPerformanceReviewEmployee(page, data).then((response) => {
            if (response.count > 0){
                setPerformanceList(response)
            }
        }).finally(() => {
            setPageLoad(false)
        })
    }

    function addPage() {
        setPage(page + 1)
    }

    function subPage() {
        setPage(page - 1)
    }

    useMemo(() => {
        // if user is superior, show performance tabs
        if (userEmployee && userEmployee.manager && userEmployee.manager.uid === user.uid) {
            setShowPerformanceTabs(true)
        }
        // if its the user itself, show performance tabs
        if (userEmployee && userEmployee.user.id === user.uid) {
            setShowPerformanceTabs(true)
        }
    }, [userEmployee, user])

    function goForm() {
        return navigate(`/create/development-plan/`, {state: {employee: userEmployee}})
    }

    // PDI detail
    useMemo(() => {
        if (performanceTemplate) {
            listSteps()
        }
    }, [performanceTemplate])

    function listSteps() {
        let data = {
            company_id: localStorage.getItem("companyId"),
            employee: userEmployee.id,
            template_id: performanceTemplate.id
        }

        performanceService.listEmployeePerformanceSteps(data).then((response) => {
            setItems(response)
        }).catch((error) => {
            toast.current.show({ severity: 'warn', summary: 'Ops', detail: JSON.stringify(error.response.data), life: 4000 });
        })
    }

    useMemo(() => {
        if (items && items.length > 0) {
            let data = {
                company_id: localStorage.getItem("companyId"),
                id: items[0].id
            }

            performanceService.performanceStepDetail(data).then((response) => {
                setStepDetail(response)
            }).catch((error) => {
                toast.current.show({ severity: 'warn', summary: 'Ops', detail: JSON.stringify(error.response.data), life: 4000 });
            })
        }
    }, [items])
    

    return (
        <ProtectedRoute>
            
            <Toast ref={toast} position="top-right" />

            <div className="container-fluid">
                
                <CareerHeader Employee={userEmployee} />

                <div className='my-4 mx-5 py-3 rounded-top' style={{minHeight: '500px'}}>

                    <CareerMenu EmployeeId={userEmployee.id} showPerformanceTabs={showPerformanceTabs} Page='PDI' />

                    <div className='bg-white py-4 rounded'>

                        {
                            pageLoad ?
                            <SkeletonSummary />
                            :
                            <>
                            <div className='row'>
                                <div className='col'>
                                    <div className='p-2 m-1'>
                                        
                                        <div className='font110 fw-bold'>
                                            Planos de Desenvolvimento Individual
                                        </div>

                                        <div>                                            
                                            { 
                                                user.uid === userEmployee.user.id ?
                                                <>
                                                    <span role='button' className='btn btn-sm btn-success btn-rounded px-3 my-2' onClick={() => goForm()}>
                                                        Criar Meu PDI
                                                    </span>
                                                </>
                                                : 
                                                <>
                                                {
                                                    user.uid === userEmployee.manager.uid ?
                                                    <>
                                                    <span role='button' className='btn btn-sm btn-success btn-rounded px-3 my-2' onClick={() => goForm()}>
                                                        Criar PDI para {userEmployee.user.name}
                                                    </span>
                                                    </>
                                                    :
                                                    null
                                                }
                                                </>
                                            }
                                        </div>

                                        {
                                            performanceList && performanceList.results && performanceList.results.length > 0 ?
                                            <>
                                            <table className='table table-borderless thover text-default borderBottom mt-3'>
                                                <thead className='borderBottom'>
                                                    <tr>
                                                        <th>
                                                            Avaliação
                                                        </th>
                                                        <th className='text-center'>
                                                            Período
                                                        </th>
                                                        <th className='text-center'>
                                                            Status
                                                        </th>
                                                        <th className='text-center'></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    performanceList.results.map((item, index) =>
                                                        <tr key={index}>
                                                            <td className="align-middle">
                                                                {
                                                                    item.statusDisplay.status_code != "CO" ?
                                                                    <>
                                                                    <a  href={`/performance-review/${item.id}/process/`} className='text-grey font110 fw-bold text-decoration-none'>
                                                                        {item.title}
                                                                    </a>
                                                                    </>
                                                                    :
                                                                    <span onClick={() => { setPerformanceTemplate(item) }} data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" className='text-green font110 fw-bold text-decoration-none' role='button'>
                                                                        {item.title}
                                                                    </span>
                                                                }
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {moment(item.start_date).format("DD-MMM-YYYY")} - {moment(item.end_date).format("DD-MMM-YYYY")}
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {item.statusDisplay.status}
                                                            </td>
                                                            <td className='align-middle text-center'>
                                                                {
                                                                    item.nextStep && item.statusDisplay.status_code != "CO" ?
                                                                    <>
                                                                    <a href={`/performance-review/step/${item.nextStep.id}/take/`} className='btn btn-sm btn-success btn-rounded px-3 my-2' onClick={() => goForm()}>
                                                                        Acessar
                                                                    </a>
                                                                    </>
                                                                    : null
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                </tbody>
                                            </table>
                                            <div className='float-end'>
                                                <nav>
                                                    <ul className='pagination pagination-sm'>
                                                        <li className='page-item'>
                                                            { 
                                                                performanceList.previous ? 
                                                                <span onClick={subPage} role="button" className='page-link text-default'>
                                                                    <FontAwesomeIcon className='' icon={solid('angle-left')} />
                                                                </span>
                                                                :
                                                                <span className='page-link text-grey disabled'>
                                                                    <FontAwesomeIcon className='' icon={solid('angle-left')} />
                                                                </span>
                                                            }
                                                        </li>
                                                        <li className='page-item'>
                                                            {
                                                                performanceList.next ?
                                                                <span onClick={addPage} role="button" className='page-link text-default'>
                                                                    <FontAwesomeIcon className='' icon={solid('angle-right')} />
                                                                </span>
                                                                :
                                                                <span className='page-link text-grey disabled'>
                                                                    <FontAwesomeIcon className='' icon={solid('angle-right')} />
                                                                </span>
                                                            }
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                            </>

                                            :
                                            null
                                        }

                                    </div>
                                </div>
                            </div>
                            </>
                        }
                        
                    </div>
                </div>
            </div>

            <div className='offcanvas offcanvas-end' style={{'--bs-offcanvas-width': 'min(75vw, 70vw)'}} data-bs-backdrop="static" tabIndex='-1' id='offcanvasRight' aria-labelledby='offcanvasRightLabel'>
                <div className='offcanvas-header border-bottom'>
                    <h6 className='offcanvas-title'>
                        {
                            stepDetail ?
                            <>
                            {performanceTemplate.title}
                            </>
                            :
                            <>
                            </>
                        }
                    </h6>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" ></button>
                </div>
                <div className='offcanvas-body'>
                        {
                            stepDetail ?
                            <>
                            <CareerPerformancePDIDetail stepDetail={stepDetail} />
                            </>
                            :
                            null
                        }
                </div>
            </div>

        </ProtectedRoute>
    )
}