import React, {useState, useEffect, useMemo} from 'react';
import ProtectedRoute from '../../routes/protected';
import { useParams } from 'react-router-dom';
import careerService from '../../services/data/career.service';
import SkeletonSummary from './skeletonSummary';
import CareerHeader from './careerHeader';
import CareerMenu from './careerMenu';
import CareerPerformanceList from './careerPerformanceList';
import userData from '../../context/user';


export default function CareerPerformance() {

    const [pageLoad, setPageLoad] = useState(true)
    const { id } = useParams()
    const [userEmployee, setUserEmployee] = useState('')
    const [showPerformanceTabs, setShowPerformanceTabs] = useState(false)
    const {user} = userData()

    useEffect(() => {

        getData()
        
        
    }, [])

    function getData() {

        let data = {
            company_id: localStorage.getItem("companyId"),
            id : id
        }

        careerService.getEmployeeSummary(data).then((response) => {    
            setUserEmployee(response)
        }).catch((error) => {
            console.log(error.response)
        }).finally(() => {
            setPageLoad(false)
        })
    
    }

    useMemo(() => {
        // if user is superior, show performance tabs
        if (userEmployee && userEmployee.manager && userEmployee.manager.uid === user.uid) {
            setShowPerformanceTabs(true)
        }
        // if its the user itself, show performance tabs
        if (userEmployee && userEmployee.user.id === user.uid) {
            setShowPerformanceTabs(true)
        }
    }, [userEmployee, user])

    return (
        <ProtectedRoute>

            <div className="container-fluid">
                
                <CareerHeader Employee={userEmployee} />

                <div className='my-4 mx-5 py-3 rounded-top' style={{minHeight: '500px'}}>

                    <CareerMenu EmployeeId={userEmployee.id} showPerformanceTabs={showPerformanceTabs} Page='performance' />

                    <div className='bg-white py-4 rounded'>

                        {
                            pageLoad ?
                            <SkeletonSummary />
                            :
                            <>
                            <div className='row'>
                                <div className='col'>
                                    <div className='p-2 m-1'>
                                        
                                        <CareerPerformanceList userEmployee={userEmployee} />

                                    </div>
                                </div>
                            </div>
                            </>
                        }
                        
                    </div>
                </div>
            </div>

        </ProtectedRoute>
    )
}