import React, {useState, useEffect, useRef, useMemo} from 'react';
import ProtectedRoute from '../../routes/protected';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Toast } from 'primereact/toast';
import { Sidebar } from 'primereact/sidebar';
import { useParams } from 'react-router-dom';
import careerService from '../../services/data/career.service';
import SkeletonSummary from './skeletonSummary';
import CareerHeader from './careerHeader';
import CareerMenu from './careerMenu';
import FormAddCareerSummary from '../../components/career/formAddCareerSummary';
import userData from '../../context/user';
import CurriculumExperience from './currExperience';
import CurriculumEducation from './currEducation';
import CurriculumEduCourse from './currEduCourse';
import CurriculumLanguage from './currLanguage';
import CurriculumWebsites from './currWebsites';


export default function Curriculum() {

    const {user} = userData()
    const [pageLoad, setPageLoad] = useState(true)
    const toast = useRef(null);
    const { id } = useParams()
    const [userEmployee, setUserEmployee] = useState('')
    const [showSidebar, setShowSidebar] = useState(false);
    const [selection, setSelection] = useState('')
    const [careerObjective, setCareerObjective] = useState('')
    const [showPerformanceTabs, setShowPerformanceTabs] = useState(false)
    useEffect(() => {

        getData()
        
        
    }, [])

    useMemo(() => {

        if (userEmployee) {
            getCareerObjective()
        }
        
    }, [userEmployee])

    function getData() {

        let data = {
            company_id: localStorage.getItem("companyId"),
            id : id
        }

        careerService.getEmployeeSummary(data).then((response) => {    
            setUserEmployee(response)
        }).catch((error) => {
            console.log(error.response)
        }).finally(() => {
            setPageLoad(false)
        })
    
    }

    function getCareerObjective() {

        let data = {
            user_id : userEmployee.user.id
        }

        careerService.getUserCareerSummary(data).then((response) => {    
            setCareerObjective(response)
        }).catch(() => {
            setCareerObjective('')
        })
    }

    useMemo(() => {
        // if user is superior, show performance tabs
        if (userEmployee && userEmployee.manager && userEmployee.manager.uid === user.uid) {
            setShowPerformanceTabs(true)
        }
        // if its the user itself, show performance tabs
        if (userEmployee && userEmployee.user.id === user.uid) {
            setShowPerformanceTabs(true)
        }
    }, [userEmployee, user])

    return (
        <ProtectedRoute>
            
            <Toast ref={toast} position="top-right" />

            <div className="container-fluid">
                
                <CareerHeader Employee={userEmployee} />

                <div className='my-4 mx-5 py-3 rounded-top' style={{minHeight: '500px'}}>

                    <CareerMenu EmployeeId={userEmployee.id} showPerformanceTabs={showPerformanceTabs} Page='curriculum' />

                    <div className='bg-white py-4 rounded'>

                        {
                            pageLoad ?
                            <SkeletonSummary />
                            :
                            <>
                            <div className='row'>
                                <div className='col'>
                                    <div className='p-2 m-1'>
                                        <div className='font110 fw-bold'>
                                            Resumo e Objetivo Profissional 
                                            {
                                                userEmployee && userEmployee.user.id ?
                                                <>
                                                {
                                                    userEmployee.user.id === user.uid ?
                                                    <>
                                                    <button className='btn btn-link border btn-sm btn-rounded text-green ms-2'  onClick={() => {setShowSidebar(true); setSelection('formCareerSummary')}} >
                                                        <FontAwesomeIcon className='font095' icon={solid('edit')} />
                                                    </button>
                                                    </>
                                                    : <></>
                                                }
                                                </>
                                                : <></>
                                            }
                                        </div>
                                        <p>
                                        {
                                            careerObjective ? 
                                            <span dangerouslySetInnerHTML={{ __html: careerObjective.career_objective }}></span> 
                                            : <>A preencher</>
                                        }
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className='row'>

                                <div className='col'>
                                {
                                    userEmployee ?
                                    <>
                                    <CurriculumExperience userId={userEmployee.user.id} />
                                    </>
                                    : null
                                }
                                </div>
                                <div className='col'>
                                {
                                    userEmployee ?
                                    <>
                                    <CurriculumEducation userId={userEmployee.user.id} />
                                    <div className='mt-2'>
                                        <CurriculumEduCourse userId={userEmployee.user.id} />
                                    </div>
                                    </>
                                    : null
                                }
                                </div>
                                <div className='col'>
                                    <CurriculumLanguage userId={userEmployee.user.id} />
                                    <div className='mt-2'>
                                        <CurriculumWebsites userId={userEmployee.user.id} />
                                    </div>
                                </div>

                            </div>
                            </>
                        }
                        
                    </div>
                </div>
            </div>

            <div className="card flex justify-content-center">
                <Sidebar className="w-50" visible={showSidebar} position="right" onHide={() => {setShowSidebar(false)}}>
                    {
                        selection && selection == 'formCareerSummary' ?
                        <FormAddCareerSummary careerObjective={careerObjective} userId={userEmployee.user.id} updateData={getCareerObjective} />
                        : null
                    }
                    
                </Sidebar>
            </div>
        </ProtectedRoute>
    )
}