import React, {useState, useMemo, useRef} from 'react';
import performanceService from '../../services/data/performance.service';
import { Toast } from 'primereact/toast';
import 'moment/locale/pt-br'
import { renderHeaderEditor } from '../../helpers/editorToolbar';    
import { Editor } from 'primereact/editor';


export default function CareerPerformancePDIDetail(props) {

    const toast = useRef(null);
    const stepDetail = props.stepDetail
    const headerEditor = renderHeaderEditor();


    return (
        <>
        <Toast ref={toast} position="top-right" />

        {
            stepDetail ?
            <>
            <div>
                {
                    stepDetail.sessions ?
                    <>
                    {
                        stepDetail.sessions.map((session, idx) =>
                        <>
                        <div key={idx} className='pb-3'>
                        <p className='fw-bold font110 border-bottom'>{session.title}</p>
                        {
                            session.questions.length > 0 ?
                            <>
                            {
                                session.questions.map((item, index) =>
                                <>
                                <div key={index} className='border rounded mb-2'>
                                    <div className='p-3 pb-0'>
                                        <p className='fw-bold mb-0'>{index+1}.</p>
                                        <span dangerouslySetInnerHTML={{ __html: item.question }}></span>
                                    </div>
                                    <div className='p-3 bg-light'>
                                        <div className=''>
                                            {
                                                item.itemStatus && item.itemStatus.length > 0  ?
                                                <>
                                                {
                                                    item.itemStatus.map((sts, index) =>
                                                    <>
                                                    <div className="my-1">
                                                        {
                                                            sts.employee_comment ?
                                                            <Editor
                                                                className='noToolbar'
                                                                name="employee_comment"
                                                                style={{ height: '80px' }}
                                                                headerTemplate={headerEditor}
                                                                readOnly
                                                                value={sts.employee_comment}
                                                            />
                                                            :
                                                            <>
                                                            {
                                                                sts.manager_comment ?
                                                                <>
                                                                <Editor
                                                                    className='noToolbar'
                                                                    name="manager_comment"
                                                                    style={{ height: '80px' }}
                                                                    headerTemplate={headerEditor}
                                                                    readOnly
                                                                    value={sts.manager_comment}
                                                                />
                                                                </>
                                                                :
                                                                <>-</>
                                                            }
                                                            </>
                                                        }
                                                        
                                                    </div>
                                                    </>
                                                    )
                                                }
                                                </>
                                                : null
                                            }
                                            
                                        </div>
                                    </div>
                                </div>
                                </>
                            )
                            }
                            </>
                            : null
                        }
                        </div>
                        </>
                        )
                    }
                    </>
                    : null
                }
                {
                    stepDetail.performanceStatus ?
                    <>
                    <p className="form-label fw-bold">Comentário Geral</p>
                    <div className='bg-light p-3 border rounded'>
                        <div className="my-1">
                            {
                                stepDetail.performanceStatus.employee_comment ?
                                <Editor
                                    className='noToolbar'
                                    name="employee_comment"
                                    style={{ height: '80px' }}
                                    headerTemplate={headerEditor}
                                    readOnly
                                    value={stepDetail.performanceStatus.employee_comment}
                                />
                                :
                                <>
                                {
                                    stepDetail.performanceStatus.manager_comment ?
                                    <>
                                    <Editor
                                        className='noToolbar'
                                        name="manager_comment"
                                        style={{ height: '80px' }}
                                        headerTemplate={headerEditor}
                                        readOnly
                                        value={stepDetail.performanceStatus.manager_comment}
                                    />
                                    </>
                                    :
                                    <>-</>
                                }
                                </>
                            }
                        </div>
                    </div>
                    </>
                    : null
                }
            </div> 
            </>
            : null
        }
        
        </>

    )
}