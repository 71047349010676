import React, { useState, useEffect, useRef, useMemo } from 'react';
import ProtectedRoute from '../../routes/protected';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useParams } from 'react-router-dom';
import performanceService from '../../services/data/performance.service';
import { Toast } from 'primereact/toast';
import { Chip } from 'primereact/chip';
import moment from "moment";
import 'moment/locale/pt-br'
import { Avatar } from 'primereact/avatar';
import NameInitials from '../../helpers/nameInitials';
import FormCreateItemStatus from '../../components/performance/forms/formCreateItemStatus';
import FormCreatePerformanceStatus from '../../components/performance/forms/formCreatePerformanceStatus';
import { Rating } from 'primereact/rating';
import userData from '../../context/user';
import SkeletonProcess from './skeletonProcess';
import TakePerformanceSuperior from './takePerformanceSuperior';
import ratingPerformance from '../../helpers/ratingPerformance';


export default function TakePerformance() {

    const [pageLoad, setPageLoad] = useState(true)

    const { id } = useParams()
    const {user} = userData()
    const [object, setObject] = useState('')
    const toast = useRef(null);
    const [currentSession, setCurrentSession] = useState('')
    const [showSummary, setShowSummary] = useState(false)
    const [dataSummary, setDataSummary] = useState('')
    // para garantir que tudo esteja respondido e possa avançar
    const [showNext, setShowNext] = useState(false)
    const [previousSession, setPreviousSession] = useState('')
    const [nextSession, setNextSession] = useState('')
    const [itemsCount, setItemsCount] = useState(0) //quantidade de goals/competencia/questions da current session
    const [statusCount, setStatusCount] = useState(0) //quantidade de status de goals/competencia/questions da current session


    useEffect(() => {
        getObject()
        
    }, [])

    function getObject() {
        let data = {
            company_id: localStorage.getItem("companyId"),
            id: id
        }
        performanceService.takePerformanceReview(data).then((response) => {
            setObject(response)
            if (!currentSession){
                setCurrentSession(response.sessions[0])
            }
        }).catch((error) => {
            console.log(error.response)
            toast.current.show({ severity: 'warn', summary: 'Ops', detail: JSON.stringify(error.response.data), life: 4000 })
        }).finally(() => {
            setPageLoad(false)
        })
    }

    function updateStatusCount(val) {
        setStatusCount(statusCount + val)
    }

    useMemo(() => {

        //para setar prox session do processo
        if (currentSession) {

            setNextSession(object.sessions[currentSession.order])

            let currentStatus = 0

            if (currentSession.goals.length > 0) {

                setItemsCount(currentSession.goals.length)

                currentSession.goals.map((item) => {
                    if (item.itemStatus && item.itemStatus.length > 0) {
                        item.itemStatus.map((sts) => {
                            if (sts.created_by === object.action_for && sts.step === id) {
                                currentStatus += 1
                            }
                        })
                    }
                })

            }

            if (currentSession.competencies.length > 0) {

                setItemsCount(currentSession.competencies.length)

                currentSession.competencies.map((item) => {
                    if (item.itemStatus && item.itemStatus.length > 0) {
                        item.itemStatus.map((sts) => {
                            if (sts.created_by === object.action_for && sts.step === id) {
                                currentStatus += 1
                            }
                        })
                    }
                })
                
            }
            if (currentSession.questions.length > 0) {

                setItemsCount(currentSession.questions.length)

                currentSession.questions.map((item) => {
                    if (item.itemStatus && item.itemStatus.length > 0) {
                        item.itemStatus.map((sts) => {
                            if (sts.created_by === object.action_for && sts.step === id) {
                                currentStatus += 1
                            }
                        })
                    }
                })
                
            }

            setStatusCount(currentStatus)

        }

    }, [currentSession])

    useMemo(() => {

        if (object && currentSession){

            if (statusCount === itemsCount) {
                //nao mostrar mais items do que tem
                if (object.sessions.length >= currentSession.order){
    
                    setShowNext(true)
    
                }

            }
        }

    }, [statusCount])

    useMemo(() => {

        if (currentSession){
            if (object.sessions[0].id === currentSession.id) {
                setPreviousSession('')
            } else {
                setPreviousSession(object.sessions[currentSession.order-2])
            }
        }

    }, [currentSession])

    function showForm(val) {

        if (val === 'SUMMARY') {

            let legn = object.sessions.length

            setPreviousSession(object.sessions[legn])

            setShowSummary(true)
        } else {
            setShowSummary(false)
        }
    }

    useEffect(() => {

        if(showSummary) {
            let data = {
                company_id: localStorage.getItem("companyId"),
                id: id
            }
            performanceService.takePerformanceReviewSummary(data).then((response) => {
                setDataSummary(response.data)
            }).catch((error) => {
                console.log(error.response)
                           
            });
            
        }

    }, [showSummary])

    function getAvancar() {
        return (
            <div className='col px-3 text-end'>
                
                {
                    nextSession ?
                    <>
                    <button className='btn btn-warning fw-bold px-4 font1em' onClick={() => {window.scrollTo({top:0,behavior:'smooth'}); setShowNext(false); setStatusCount(0); setCurrentSession(nextSession)}}>
                        Avançar <FontAwesomeIcon className='' icon={solid('chevron-right')} />
                    </button>
                    </>
                    :
                    <>
                    <button className='btn btn-warning fw-bold px-4 font1em' onClick={() => {window.scrollTo({top:0,behavior:'smooth'}); setShowNext(false); setStatusCount(0); setCurrentSession(''); showForm('SUMMARY')}}>
                        Avançar <FontAwesomeIcon className='' icon={solid('chevron-right')} />
                    </button>
                    </>
                
                }
                
            </div>
        )
    }

    function getPrevious() {
        return (
            <div className='col px-3 text-start'>
                {
                    previousSession ?
                    <button className='btn btn-outline-warning fw-bold px-4 font1em' onClick={() => {window.scrollTo({top:0,behavior:'smooth'}); setShowNext(false); setStatusCount(0); setCurrentSession(previousSession)}}>
                        <FontAwesomeIcon className='' icon={solid('chevron-left')} /> Voltar 
                    </button>
                    : null
                }
            </div>
        )
    }

    function statusEdit(item) {
        
        const hasStatus = item.itemStatus.find((sts => sts.created_by === object.action_for && sts.step === id ))
        if (hasStatus) {
            return true
        }
        return false
    }

    return (
        <ProtectedRoute>

            <Toast ref={toast} position="top-right" />

            <div className="container-fluid">
                <div className='row flex-nowrap'>
                    <div className='col text-bg-green p-3'>
                        {
                            object?
                            <div className="fs-6 fw-bold ps-5 text-white">
                            {object.title} : {object.performance.title}
                            </div>
                            :
                            <>
                            <a href='/' className="fs-6 fw-bold ps-5 text-decoration-none text-white">
                                <FontAwesomeIcon className='' icon={solid('arrow-left')} /> Voltar
                            </a>
                            </>
                        }
                    </div>
                </div>
                {
                    object ?
                    <>
                    <div className='p-3 my-4 mx-5 bg-white contentShadowFull rounded'>
                        <div className='mb-2 border-bottom pb-2'>
                            <table className=''>
                                <tbody>
                                    <tr>
                                        <td className='pe-2'>
                                            {
                                                object.employee.user.photo ?
                                                <>
                                                <img src={object.employee.user.photo} alt='photo' height="50" className='rounded-circle' />
                                                </>
                                                :
                                                <Avatar label={NameInitials( object.employee.user.name )} style={{ backgroundColor: '#42c0fb', color: '#ffffff', fontWeight: 'bold' }} shape="circle" />
                                            }
                                        </td>
                                        <td className='pe-4'>
                                            <span className='fw-bold fs-6'>{object.employee.user.name}</span>
                                            <br/><span className='text-grey'>{object.jobposition.title}</span>
                                        </td>
                                        <td className='ps-4 border-start'>
                                                Seja bem vindo(a)!
                                                <br/>
                                                Para cada um dos itens apresentados, insira sua avaliação e comentário.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className=''>
                            <table className='table table-sm table-borderless mb-0'>
                                <tbody>
                                    <tr>
                                        <td>
                                            <span className='fw-bold pe-2'>Status desta Etapa:</span>
                                            {
                                                object.status === 'PE' || object.status === 'NI' ?
                                                <>
                                                <span className='bg-danger text-white bg-opacity-75 rounded-pill px-4 py-1 ms-1'>{object.statusDisplay}</span>
                                                </>
                                                :
                                                <span className='bg-success text-white bg-opacity-75 rounded-pill px-4 py-1 ms-1'>{object.statusDisplay}</span>
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    </>
                    :
                    null
                }
                <div className='d-flex my-4 mx-5 bg-white contentShadowFull rounded-top' style={{minHeight: '500px'}}>

                {
                    pageLoad ?
                    <SkeletonProcess />
                    :
                    <>
                    {
                        object && object.step_type !== 'AS' ? //avaliar superior ?
                        <>
                        <div className='flex-column d-none d-md-block col-2 bg-light'>
                            <div className='pt-3'>
                                <p className='fs-6 fw-bold ps-3'>Processo</p>
                                <nav className='nav flex-column border-top'>
                                {
                                    object.sessions.map((item, index) => 
                                    <>
                                    {
                                        currentSession.id === item.id ?
                                        <li key={index} className='nav-item bg-white py-2 border-bottom'>
                                            <span className='nav-link text-default fw-bold'>{index+1} - {item.title}</span>
                                        </li>
                                        :
                                        <li key={index} className='nav-item py-2 border-bottom'>
                                            <span className='nav-link text-default'>{index+1} - {item.title}</span>
                                        </li>
                                    }
                                    </>
                                    )
                                }
                                {
                                    showSummary ?
                                    <li className='nav-item bg-white py-2 border-bottom'>
                                        <span className='nav-link disabled text-default fw-bold'>{object.sessions.length +1} - Geral</span>
                                    </li>
                                    :
                                    <li className='nav-item py-2 border-bottom'>
                                        <span className='nav-link disabled text-default'>{object.sessions.length +1} - Geral</span>
                                    </li>
                                }
                                    
                                </nav>
                            </div>
                        </div>
                        </>
                        :
                        null
                    }
                    
                    <div className='col paddingBottom200'>
                        
                        <div className='p-3 mb-5'>
                        {
                            showSummary ?
                            <>
                            {
                                object ?
                                <>
                                <p className='fs-6 fw-bold ps-3'>Geral</p>
                                <hr/>
                                <div className='row'>
                                    
                                    <div className='col'>
                                        <p className='lead fw-bold'>
                                            Parabéns {user.social_name ? <>{user.social_name.split(" ")[0]}</> : <>{user.first_name}</>},
                                        </p>
                                        <p>
                                            Você completou com sucesso a <span className='fw-bold text-green'>{object.title} : {object.performance.title}</span>.
                                        </p>
                                        {
                                            dataSummary ?
                                            <>
                                            {
                                                object.performance.assessment_type != 'PD' ?
                                                <>
                                                <p>Baseando-se nas suas respostas, sua avaliação geral é:</p>
                                                <div className='d-flex align-middle pb-3'>
                                                    <Rating value={ratingPerformance(dataSummary.final.evaluation)} readOnly cancel={false} stars={5} />  <span className='ps-2 pt-1 fw-bold'>{dataSummary.final.evaluationDisplay}</span>
                                                </div>
                                                </>
                                                :
                                                null
                                            }
                                            
                                            {
                                                dataSummary.files.length > 0 ?
                                                <>
                                                <div className='col-md-6 bg-light rounded my-3 p-3'>
                                                    <div className=''>
                                                        <label className="form-label fw-bold">
                                                            {dataSummary.files.length} {dataSummary.files.length > 1 ? <>Evidências</>:<>Evidência</>}
                                                        </label>
                                                    </div>
                                                    {
                                                        dataSummary.files.map((item, index) => 
                                                        <span key={index} className='m-1'>
                                                            <a href={item.file} className='btn btn-sm btn-success font095' target='_blank' rel="noopener noreferrer">
                                                                {index+1} - Ver Arquivo 
                                                            </a>
                                                        </span>
                                                        )
                                                    }
                                                </div>
                                                </>
                                                :
                                                <>
                                                {
                                                    object.status === 'RE' && object.step_type === 'RN' ?
                                                    <div className='col-md-6 bg-light rounded my-3 p-3'>
                                                        <p>Nenhuma evidência enviada</p>
                                                    </div>
                                                    :
                                                    null
                                                }
                                                </>
                                            }
                                            <FormCreatePerformanceStatus updateObject={getObject} object={object.performanceStatus} objectStatus={object.status} step_id={id} score={dataSummary} evaluation={dataSummary.final.evaluation} itemType={object.step_type} employeeId={object.employee.id} assessmentId={object.performance.id} />
                                            </>
                                            :
                                            null
                                        }
                                        <hr/>
                                        <div className='text-end'>
                                            <button className='btn btn-outline-warning fw-bold px-4 font1em' onClick={() => {setShowNext(true); showForm(''); setStatusCount(0); setCurrentSession(object.sessions[object.sessions.length-1])}}>
                                                <FontAwesomeIcon className='' icon={solid('chevron-left')} /> Voltar 
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                
                                </>
                                :
                                <>Carregando...</>
                            }
                            </>
                            :
                            <>
                            {
                                object.step_type === 'AS' ? //avaliar superior
                                <>
                                <TakePerformanceSuperior object={object}  updateObject={getObject} />
                                </>
                                :
                                <>
                                <p className='fs-6 fw-bold'>
                                    {currentSession.title}
                                </p>
                                <hr/>
                            
                                {
                                    currentSession.session_type === 'GO' ?
                                    <>
                                    {
                                        currentSession.goals.length > 0 ?
                                        <>
                                        <div className='row ps-3'>
                                            <div className='col fs-6 pb-2'>Você previsa avaliar <span className='fw-bold'>{currentSession.goals.length}</span> objetivos</div>
                                            {showNext ? <><hr/></> : null }
                                        </div>
                                        <div className='row'>
                                            {
                                                previousSession ?
                                                getPrevious()
                                                :
                                                null
                                            }

                                            {
                                                showNext ?
                                                getAvancar()
                                                :null
                                            }
                                            </div>
                                        
                                        <div className='p-3'>
                                        {
                                            currentSession.goals.map((item, index) =>
                                                <>
                                                <div key={index} className='border rounded mb-2'>
                                                    <div>
                                                        <div className='p-3 pb-0'>
                                                            <p className='fw-bold mb-0'>{index+1}.</p>
                                                            <span className='text-green fw-bold fs-6'>{item.title}</span>
                                                            <br/>
                                                            <span dangerouslySetInnerHTML={{ __html: item.metric }}></span>
                                                        </div>
                                                        <table className='table table-sm mb-0 border-top'>
                                                            <thead className='bg-green-light'>
                                                                <tr>
                                                                    <th className='text-center'>
                                                                        Tipo
                                                                    </th>
                                                                    <th className='text-center'>
                                                                        Período
                                                                    </th>
                                                                    <th className='text-center'>
                                                                        Status
                                                                    </th>
                                                                    <th className='text-center'>
                                                                        Relacionado a
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td className="text-center">
                                                                        <Chip label={item.goal_type_display} />
                                                                    </td>
                                                                    <td className="align-middle text-center">
                                                                        {moment(item.start_date).format("DD-MMM-YYYY")} - {moment(item.end_date).format("DD-MMM-YYYY")} 
                                                                    </td>
                                                                    <td className="align-middle text-center">
                                                                    {
                                                                        item.goalLastStatus && item.goalLastStatus.status ?
                                                                        <>
                                                                        {
                                                                            item.goalLastStatus.status === 'CS' ?
                                                                            <>
                                                                            <FontAwesomeIcon className='text-green' icon={solid('circle-check')} /> {item.goalLastStatus.status_display}
                                                                            </>
                                                                            :
                                                                            <>{item.goalLastStatus.status_display}</>
                                                                        }
                                                                        </>
                                                                        :
                                                                        <>Não Iniciado</>
                                                                    }
                                                                    </td>
                                                                    <td className="align-middle text-center">
                                                                    {
                                                                        item.related_goal ?
                                                                        <>
                                                                        {item.related_goal.title}
                                                                        </>
                                                                        :
                                                                        <>-</>
                                                                    }
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    {
                                                        object.step_type === 'RN' && item.itemStatus && item.itemStatus.length > 0?
                                                        <>
                                                        <div className='row py-3 mx-0 bg-white'>
                                                            <div className='col'>
                                                                <b>Avaliação do Gestor</b>
                                                                {
                                                                    item.itemStatus.filter(sts => sts.created_by === object.action_for && sts.step !== id ).map((sts, index) => 
                                                                    <>
                                                                    {
                                                                        index === 0 ?
                                                                        <>
                                                                        <div className='d-flex align-middle'>
                                                                            <Rating value={sts.manager_evaluation} readOnly cancel={false} stars={5} /> <span className='ps-2 pt-1'>{sts.manager_evaluation === 1 ? 'Não Iniciado' : sts.manager_evaluation === 2 ? 'Precisa Melhorar' : sts.manager_evaluation === 3 ? 'Dentro da Expectativa' : sts.manager_evaluation === 4 ? 'Acima da Expectativa' : 'Se Superou' }</span>
                                                                        </div>
                                                                        <div className='mt-2'>
                                                                            <p dangerouslySetInnerHTML={{__html: sts.manager_comment }}></p>
                                                                        </div>
                                                                        </>
                                                                        :
                                                                        null
                                                                    }
                                                                    </>
                                                                    )
                                                                }
                                                            </div>
                                                            <div className='col'>
                                                                <b>Auto Avaliação</b>
                                                                {
                                                                    item.itemStatus.filter(sts => sts.created_by === object.employee.id).map((sts) => 
                                                                    <>
                                                                    <div className='d-flex align-middle'>
                                                                        <Rating value={sts.employee_evaluation} readOnly cancel={false} stars={5} /> <span className='ps-2 pt-1'>{sts.employee_evaluation === 1 ? 'Não Iniciado' : sts.employee_evaluation === 2 ? 'Precisa Melhorar' : sts.employee_evaluation === 3 ? 'Dentro da Expectativa' : sts.employee_evaluation === 4 ? 'Acima da Expectativa' : 'Se Superou' }</span>
                                                                    </div>
                                                                    <div className='mt-2'>
                                                                        <p dangerouslySetInnerHTML={{__html: sts.employee_comment }}></p>
                                                                    </div>
                                                                    </>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                        </>
                                                        :
                                                        null
                                                    }
                                                    <div className='p-3 bg-light'>
                                                        <p className='fw-bold font110 border-bottom'>Sua avaliação</p>
                                                        <div className=''>
                                                            {
                                                                item.itemStatus && item.itemStatus.length > 0  ?
                                                                <>
                                                                {
                                                                    statusEdit(item) ?
                                                                    <>
                                                                    {
                                                                        item.itemStatus.filter((sts => sts.created_by === object.action_for && sts.step === id )).map((sts) => {
                                                                            return <FormCreateItemStatus stepId={id} objectStatus={object.status} object={sts} objectType={currentSession.session_type} itemType={object.step_type} employeeId={object.employee.id} assessmentId={object.performance.id} sessionId={currentSession.id} objectId={item.id} />
                                                                        })
                                                                    }
                                                                    </>
                                                                    :
                                                                    <>
                                                                    <FormCreateItemStatus stepId={id} updateStatusCount={updateStatusCount} objectStatus={object.status} objectType={currentSession.session_type} itemType={object.step_type} employeeId={object.employee.id} assessmentId={object.performance.id} sessionId={currentSession.id} objectId={item.id} />
                                                                    </>
                                                                }
                                                                </>
                                                                :
                                                                <>
                                                                <FormCreateItemStatus stepId={id} updateStatusCount={updateStatusCount} objectStatus={object.status} objectType={currentSession.session_type} itemType={object.step_type} employeeId={object.employee.id} assessmentId={object.performance.id} sessionId={currentSession.id} objectId={item.id} />
                                                                </>
                                                            }
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                                </>
                                            )
                                        }
                                        </div>
                                        </>
                                        :
                                        <>
                                        <p>Nenhum objetivo adicionado ainda</p>
                                        </>
                                    }
                                    </>
                                    : null
                                }
                                {
                                    currentSession.session_type === 'CO' ?
                                    <>
                                    {
                                        currentSession.competencies.length > 0 ?
                                        <>
                                        <div className='row ps-3'>
                                            <div className='col fs-6 pb-2'>Você previsa avaliar <span className='fw-bold'>{currentSession.competencies.length}</span> competências</div>
                                            {showNext ? <><hr/></> : null }
                                        </div>
                                        <div className='row'>
                                            {
                                                previousSession ?
                                                getPrevious()
                                                :
                                                null
                                            }

                                            {
                                                showNext ?
                                                getAvancar()
                                                :null
                                            }
                                            </div>
                                        <div className='p-3'>
                                        {
                                            currentSession.competencies.map((item, index) =>
                                            <div key={index} className='border rounded mb-2'>
                                                <div className='p-3'>
                                                    <p className='fw-bold mb-0'>{index+1}.</p>
                                                    <span className='text-green fw-bold fs-6'>{item.competency.title}</span>
                                                    <br/>
                                                    <span dangerouslySetInnerHTML={{ __html: item.description }}></span>
                                                </div>
                                                {
                                                    object.step_type === 'RN' && item.itemStatus && item.itemStatus.length > 0?
                                                    <>
                                                    <div className='row py-3 mx-0 bg-white'>
                                                        <div className='col'>
                                                            <b>Avaliação do Gestor</b>
                                                            {
                                                                item.itemStatus.filter(sts => sts.created_by === object.action_for && sts.step !== id).map((sts) => 
                                                                <>
                                                                <div className='d-flex align-middle'>
                                                                    <Rating value={sts.manager_evaluation} readOnly cancel={false} stars={5} /> <span className='ps-2 pt-1'>{sts.manager_evaluation === 1 ? 'Não Iniciado' : sts.manager_evaluation === 2 ? 'Precisa Melhorar' : sts.manager_evaluation === 3 ? 'Dentro da Expectativa' : sts.manager_evaluation === 4 ? 'Acima da Expectativa' : 'Se Superou' }</span>
                                                                </div>
                                                                <div className='mt-2'>
                                                                    <p dangerouslySetInnerHTML={{__html: sts.manager_comment }}></p>
                                                                </div>
                                                                </>
                                                                )
                                                            }
                                                        </div>
                                                        <div className='col'>
                                                            <b>Auto Avaliação</b>
                                                            {
                                                                item.itemStatus.filter(sts => sts.created_by === object.employee.id).map((sts) => 
                                                                <>
                                                                <div className='d-flex align-middle'>
                                                                    <Rating value={sts.employee_evaluation} readOnly cancel={false} stars={5} /> <span className='ps-2 pt-1'>{sts.employee_evaluation === 1 ? 'Não Iniciado' : sts.employee_evaluation === 2 ? 'Precisa Melhorar' : sts.employee_evaluation === 3 ? 'Dentro da Expectativa' : sts.employee_evaluation === 4 ? 'Acima da Expectativa' : 'Se Superou' }</span>
                                                                </div>
                                                                <div className='mt-2'>
                                                                    <p dangerouslySetInnerHTML={{__html: sts.employee_comment }}></p>
                                                                </div>
                                                                </>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                    </>
                                                    :
                                                    null
                                                }
                                                <div className='p-3 bg-light'>
                                                    <p className='fw-bold font110 border-bottom'>Sua avaliação</p>
                                                    <div className=''>
                                                        {
                                                            item.itemStatus && item.itemStatus.length > 0 ?
                                                            <>
                                                            {
                                                                statusEdit(item) ?
                                                                <>
                                                                {
                                                                    item.itemStatus.filter((sts => sts.created_by === object.action_for && sts.step === id)).map((sts) => {
                                                                        return <FormCreateItemStatus stepId={id} objectStatus={object.status} object={sts} objectType={currentSession.session_type} itemType={object.step_type} employeeId={object.employee.id} assessmentId={object.performance.id} sessionId={currentSession.id} objectId={item.id} />
                                                                    })
                                                                }
                                                                </>
                                                                :
                                                                <>
                                                                <FormCreateItemStatus stepId={id} updateStatusCount={updateStatusCount} objectStatus={object.status} objectType={currentSession.session_type} itemType={object.step_type} employeeId={object.employee.id} assessmentId={object.performance.id} sessionId={currentSession.id} objectId={item.id} />
                                                                </>
                                                            }
                                                            </>
                                                            :
                                                            <>
                                                            <FormCreateItemStatus stepId={id} updateStatusCount={updateStatusCount} objectStatus={object.status} objectType={currentSession.session_type} itemType={object.step_type} employeeId={object.employee.id} assessmentId={object.performance.id} sessionId={currentSession.id} objectId={item.id} />
                                                            </>
                                                        }
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            )
                                        }
                                        </div>
                                        </>
                                        :
                                        <>
                                        <p>Nenhuma competência adicionada ainda</p>
                                        </>
                                    }
                                    </>
                                    : null
                                }
                                
                                {
                                    currentSession.session_type === 'QE' ?
                                    <>
                                    {
                                        currentSession.questions.length > 0 ?
                                        <>
                                        <div className='row ps-3'>
                                            <div className='col fs-6 pb-2'>Você previsa responder <span className='fw-bold'>{currentSession.questions.length}</span> perguntas</div>
                                            {showNext ? <><hr/></> : null }
                                        </div>
                                        <div className='row'>
                                            {
                                                previousSession ?
                                                getPrevious()
                                                :
                                                null
                                            }

                                            {
                                                showNext ?
                                                getAvancar()
                                                :null
                                            }
                                            </div>
                                        <div className='p-3'>
                                        {
                                            currentSession.questions.map((item, index) =>
                                            <div key={index} className='border rounded mb-2'>
                                                <div className='p-3'>
                                                    <p className='fw-bold mb-0'>{index+1}.</p>
                                                    <span className='fs-6 fw-normal' dangerouslySetInnerHTML={{ __html: item.question }}></span>
                                                </div>
                                                {
                                                    object.step_type === 'RN' && item.itemStatus && item.itemStatus.length > 0?
                                                    <>
                                                    <div className='row py-3 mx-0 bg-white'>
                                                        <div className='col'>
                                                            <b>Avaliação do Gestor</b>
                                                            {
                                                                item.itemStatus.filter(sts => sts.created_by === object.action_for && sts.step !== id).map((sts, index) => 
                                                                <>
                                                                <div className='mt-2'>
                                                                    <p dangerouslySetInnerHTML={{__html: sts.manager_comment }}></p>
                                                                </div>
                                                                </>
                                                                )
                                                            }
                                                        </div>
                                                        <div className='col'>
                                                            <b>Auto Avaliação</b>
                                                            {
                                                                item.itemStatus.filter(sts => sts.created_by === object.employee.id).map((sts) => 
                                                                <>
                                                                <div className='mt-2'>
                                                                    <p dangerouslySetInnerHTML={{__html: sts.employee_comment }}></p>
                                                                </div>
                                                                </>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                    </>
                                                    :
                                                    null
                                                }
                                                <div className='p-3 bg-light'>
                                                    <p className='fw-bold font110 border-bottom'>Sua avaliação</p>
                                                    <div className=''>
                                                        {
                                                            item.itemStatus && item.itemStatus.length > 0 ?
                                                            <>
                                                            {
                                                                statusEdit(item) ?
                                                                <>
                                                                {
                                                                    item.itemStatus.filter((sts => sts.created_by === object.action_for && sts.step === id )).map((sts) => {
                                                                        return <FormCreateItemStatus stepId={id} objectStatus={object.status} object={sts} objectType={currentSession.session_type} itemType={object.step_type} employeeId={object.employee.id} assessmentId={object.performance.id} sessionId={currentSession.id} objectId={item.id} />
                                                                    })
                                                                }
                                                                </>
                                                                :
                                                                <>
                                                                <FormCreateItemStatus stepId={id} updateStatusCount={updateStatusCount} objectStatus={object.status} objectType={currentSession.session_type} itemType={object.step_type} employeeId={object.employee.id} assessmentId={object.performance.id} sessionId={currentSession.id} objectId={item.id} />
                                                                </>
                                                            }
                                                            </>
                                                            :
                                                            <>
                                                            <FormCreateItemStatus stepId={id} updateStatusCount={updateStatusCount} objectStatus={object.status} objectType={currentSession.session_type} itemType={object.step_type} employeeId={object.employee.id} assessmentId={object.performance.id} sessionId={currentSession.id} objectId={item.id} />
                                                            </>
                                                        }
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            )
                                        }
                                        </div>
                                        </>
                                        :
                                        <p>Nenhuma pergunta adicionada ainda</p>
                                    }
                                    </>
                                    : null
                                }
                                </>
                            }
                            </>
                        }
                            <div className='row'>
                            {
                                previousSession ?
                                getPrevious()
                                :
                                null
                            }

                            {
                                showNext ?
                                getAvancar()
                                :null
                            }
                            </div>
                        
                        </div>
                    </div>
                    </>
                }
                    
                </div>
            </div>
        </ProtectedRoute>
    )
}