import React, {useState, useRef, useMemo} from 'react';
import moment from "moment";
import 'moment/locale/pt-br'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Toast } from 'primereact/toast';
import performanceService from '../../services/data/performance.service';
import CareerPerformanceSteps from './careerPerformanceSteps'


export default function CareerPerformanceList(props) {

    const userEmployee = props.userEmployee
    const [performanceList, setPerformanceList] = useState([])
    const [page, setPage] = useState(1)
    const toast = useRef(null);
    const [showSteps, setShowSteps] = useState(false)
    const [stepId, setStepId] = useState('')
    const [performanceTemplate, setPerformanceTemplate] = useState('')

    useMemo(() => {
        listPerformances()
    }, [page])

    function listPerformances() {
        let data = {
            company_id: localStorage.getItem("companyId"),
            employees: userEmployee.id,
            assessment_type: Array('AD', 'BD', 'SD', 'TD')
        }

        performanceService.listPerformanceReviewEmployee(page, data).then((response) => {
            if (response.count > 0){
                setPerformanceList(response)
            }
        })
    }

    function addPage() {
        setPage(page + 1)
    }

    function subPage() {
        setPage(page - 1)
    }

    return (
        <>

        <Toast ref={toast} position="top-right" />

        {
            showSteps ?
            <>
            <div>
                <span role="button" className="nav-link mt-2 mb-3" onClick={() => {setShowSteps(false); setStepId('')}}>
                    <span className="text-green fw-bold"><FontAwesomeIcon icon={solid('chevron-left')} /> Voltar</span>
                </span>
            </div>
            <CareerPerformanceSteps template={performanceTemplate} stepId={stepId} employee={userEmployee} />
            </>
            :
            <>
            <div className='font110 fw-bold'>
                Meu Desempenho
            </div>

            {
                performanceList && performanceList.results && performanceList.results.length > 0 ?
                <>
                <table className='table table-borderless thover text-default borderBottom mt-3'>
                    <thead className='borderBottom'>
                        <tr>
                            <th>
                                Avaliação
                            </th>
                            <th className='text-center'>
                                Gestor
                            </th>
                            <th className='text-center'>
                                Status
                            </th>
                            <th className='text-center'>
                                Potencial
                            </th>
                            <th className='text-center'>
                                Desempenho
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        performanceList.results.map((item, index) =>
                            <tr key={index}>
                                <td className="align-middle">
                                    <span onClick={() => {setShowSteps(true); setStepId(item.id); setPerformanceTemplate(item) }} className='text-green font110 fw-bold text-decoration-none' role='button'>
                                        {item.title}
                                    </span>
                                    <br/>
                                    {item.assessmentTypeDisplay}
                                    <br/>
                                    <span className='text-grey'>
                                    {moment(item.start_date).format("DD-MMM-YYYY")} - {moment(item.end_date).format("DD-MMM-YYYY")} 
                                    </span>
                                </td>
                                <td className="align-middle text-center">
                                    {
                                        item.managers?
                                        <>
                                        {
                                            item.managers.map((manager, idx) =>
                                                <span key={idx}>{manager.name}</span>
                                            )
                                        }
                                        </>
                                        : <></>
                                    }
                                </td>
                                <td className="align-middle text-center">
                                    {item.statusDisplay.status}
                                </td>
                                <td className="align-middle text-center">

                                    {
                                        item.performanceStatus && item.performanceStatus.potential && item.statusDisplay.status_code === "CO" ?
                                        <>{item.performanceStatus.potential}</>
                                        : <>-</>
                                    }
                                
                                </td>
                                <td className="align-middle text-center">
                                    {
                                        item.performanceStatus && item.performanceStatus.performance && item.statusDisplay.status_code === "CO" ?
                                        <>{item.performanceStatus.performance}</>
                                        : <>-</>
                                    }
                                </td>
                            </tr>
                        )
                    }
                    </tbody>
                </table>
                <div className='float-end'>
                    <nav>
                        <ul className='pagination pagination-sm'>
                            <li className='page-item'>
                                { 
                                    performanceList.previous ? 
                                    <span onClick={subPage} role="button" className='page-link text-default'>
                                        <FontAwesomeIcon className='' icon={solid('angle-left')} />
                                    </span>
                                    :
                                    <span className='page-link text-grey disabled'>
                                        <FontAwesomeIcon className='' icon={solid('angle-left')} />
                                    </span>
                                }
                            </li>
                            <li className='page-item'>
                                {
                                    performanceList.next ?
                                    <span onClick={addPage} role="button" className='page-link text-default'>
                                        <FontAwesomeIcon className='' icon={solid('angle-right')} />
                                    </span>
                                    :
                                    <span className='page-link text-grey disabled'>
                                        <FontAwesomeIcon className='' icon={solid('angle-right')} />
                                    </span>
                                }
                            </li>
                        </ul>
                    </nav>
                </div>
                </>

                :
                <>
                Nenhum item criado ainda
                </>
            }
            </>
        }
        </>

    )
}