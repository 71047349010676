import React, {useEffect, useState, useMemo } from 'react';
import ProtectedRoute from '../../routes/protected';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import FormCreateDevPlan from '../../components/performance/forms/formCreateDevPlan';
import { useNavigate, useLocation } from 'react-router-dom';

export default function CreateDevelopmentPlan() {

    const navigate = useNavigate();
    const location = useLocation();
    const [employee, setEmployee] = useState(null);

    useEffect(() => {

        if (location && location.state && location.state.employee) {
            setEmployee(location.state.employee)
        }

    }, [location])

    
    return (
        <ProtectedRoute>

            <div className="container-fluid">
                <div className='row flex-nowrap'>
                    <div className='col text-bg-green p-3'>
                        <span className="fs-6 fw-bold ps-5 text-decoration-none text-white" role='button' onClick={() => navigate(-1)}>
                            <FontAwesomeIcon className='' icon={solid('arrow-left')} /> Plano de Desenvolvimento
                        </span>
                    </div>
                </div>
                <div className='d-flex-auto my-4 mx-5 pt-3 bg-white contentShadow rounded-top paddingBottom200' style={{minHeight: '500px'}}>
                    <div className='col-md-6 col-10'>
                        <div className='ps-3'>
                            {
                                employee ?
                                <FormCreateDevPlan employee={employee} />
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </ProtectedRoute>
    )
}