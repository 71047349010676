import React, {useState, useEffect} from 'react';

export default function CareerMenu(props) {

    const userEmployee = props.EmployeeId
    const page = props.Page
    const [showPerformanceTabs, setShowPerformanceTabs] = useState(props.showPerformanceTabs)

    useEffect(() => {
        setShowPerformanceTabs(props.showPerformanceTabs)
    }, [props.showPerformanceTabs])

    return (
        <>
        <ul className='nav nav-tabs px-3'>

            {
                page == 'summary' ?
                <li className='nav-item'>
                    <span className='nav-link active'>
                        Sumário
                    </span>
                </li>                
                :
                <li className='nav-item'>
                    <a href={`/career/summary/${userEmployee}/`} className='nav-link text-green'>
                        Sumário
                    </a>
                </li>
            }

            {
                page == 'curriculum' ?
                <li className='nav-item'>
                    <span className='nav-link active'>
                        Perfil Profissional
                    </span>
                </li>
                :
                <li className='nav-item'>
                    <a href={`/career/curriculum/${userEmployee}/`} className='nav-link text-green'>
                        Perfil Profissional
                    </a>
                </li>
            }

            {
                showPerformanceTabs ?
                <>
                {
                    page == 'performance' ?
                    <li className='nav-item'>
                        <span className='nav-link active'>
                            Performance
                        </span>
                    </li>
                    :
                    <li className='nav-item'>
                        <a href={`/career/performance/${userEmployee}/`} className='nav-link text-green'>
                            Performance
                        </a>
                    </li>
                }

                {
                    page == 'PDI' ?
                    <li className='nav-item'>
                        <span className='nav-link active'>
                            PDI
                        </span>
                    </li>
                    :
                    <li className='nav-item'>
                        <a href={`/career/pdi/${userEmployee}/`} className='nav-link text-green'>
                            PDI
                        </a>
                    </li>
                }
                </>
                : null
                
            }

        </ul>
        </>
    )
}