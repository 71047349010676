import React, {useState, useMemo, useRef} from 'react';
import performanceService from '../../services/data/performance.service';
import { Toast } from 'primereact/toast';
import moment from "moment";
import 'moment/locale/pt-br'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Chip } from 'primereact/chip';
import FormCreateItemStatus from '../../components/performance/forms/formCreateItemStatus';
import { renderHeaderEditor } from '../../helpers/editorToolbar';    
import { Editor } from 'primereact/editor';


export default function CareerPerformanceSteps(props) {

    const template = props.template
    const employee = props.employee
    const [items, setItems] = useState([])
    const toast = useRef(null);
    const [step, setStep] = useState({})
    const [stepDetail, setStepDetail] = useState({})
    const headerEditor = renderHeaderEditor();


    useMemo(() => {
            if (template && employee) {
                listSteps()
            }
        }, [template, employee])
    
    function listSteps() {
            let data = {
                company_id: localStorage.getItem("companyId"),
                employee: employee.id,
                template_id: template.id
            }
    
            performanceService.listEmployeePerformanceSteps(data).then((response) => {
                setItems(response)
            }).catch((error) => {
                toast.current.show({ severity: 'warn', summary: 'Ops', detail: JSON.stringify(error.response.data), life: 4000 });
            })
        }
    
        useMemo(() => {
            if (step && step.id) {
                
                let data = {
                    company_id: localStorage.getItem("companyId"),
                    id: step.id
                }

                performanceService.performanceStepDetail(data).then((response) => {
                    setStepDetail(response)
                }).catch((error) => {
                    toast.current.show({ severity: 'warn', summary: 'Ops', detail: JSON.stringify(error.response.data), life: 4000 });
                })
            }
        }, [step])

    return (
        <>
        <Toast ref={toast} position="top-right" />

        <div className='font110 fw-bold'>
            {template.title} <a href={`/performance-review/${template.id}/process/`} target='_blank' rel="noopener noreferrer" className="text-green noDecoration">
                                <FontAwesomeIcon className='ms-1' icon={solid('external-link-alt')} />
                                </a>
        </div>

        {
            items ?
            <>
            <table className='table table-borderless thover text-default borderBottom mt-3'>
                <thead className='borderBottom'>
                    <tr>
                        <th>
                            Etapa
                        </th>
                        <th className='text-center'>
                            Responsável
                        </th>
                        <th className='text-center'>
                            Status
                        </th>
                        <th className='text-center'>
                            Data que Realizou
                        </th>
                        <th className='text-center'>
                            Desempenho
                        </th>
                        <th className='text-center'>
                            Potencial
                        </th>
                    </tr>
                </thead>
                <tbody>
                {
                    items.map((item, index) =>
                        <tr key={index}>
                            <td className="align-middle">
                                {
                                    template.statusDisplay.status_code === 'AT' || template.statusDisplay.status_code === 'EP' ?
                                    <>
                                    {
                                        item.status_code === 'RE' && item.action_for.id === employee.id ?
                                        <span role='button' onClick={() => setStep(item)} className='text-green font110 fw-bold text-decoration-none' data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                                            {item.order} - {item.title}
                                        </span>
                                        : 
                                        <span className='font110 fw-bold text-decoration-none'>
                                            {item.order} - {item.title}
                                        </span>
                                    }
                                    </>
                                    :
                                    <span role='button' onClick={() => setStep(item)} className='text-green font110 fw-bold text-decoration-none' data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                                        {item.order} - {item.title}
                                    </span>
                                }
                            </td>
                            <td className="align-middle text-center">
                                {item.action_for.full_name}
                            </td>
                            <td className="align-middle text-center">
                                {item.statusDisplay}
                            </td>
                            <td className='align-middle text-center'>
                                {
                                    item.date_completed ?
                                    <>
                                        {moment(item.date_completed).format("DD-MMM-YYYY hh:mm:ss")}
                                    </>
                                    : <>-</>
                                }
                            </td>
                            <td className='align-middle text-center'>
                                {
                                    item.status && item.status.score && item.status.score.final && item.status.score.final.evaluationDisplay ?
                                    <>
                                        {item.status.score.final.evaluationDisplay} 
                                    </>
                                    : <>-</>
                                }
                            </td>
                            <td className='align-middle text-center'>
                                {
                                    item.status && item.status.potential ?
                                    <>
                                    {item.status.potential}
                                    </>
                                    : <>-</>
                                }
                            </td>
                        </tr>
                    )
                }
                </tbody>
            </table>
            </>
            :
            <>
            Nenhum item encontrado
            </>
        }
        {
            step && stepDetail ?
            <>
            <div className='offcanvas offcanvas-end' style={{'--bs-offcanvas-width': 'min(75vw, 70vw)'}} data-bs-backdrop="static" tabIndex='-1' id='offcanvasRight' aria-labelledby='offcanvasRightLabel'>
                <div className='offcanvas-header border-bottom'>
                    <h6 className='offcanvas-title'>{stepDetail.title}</h6>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" ></button>
                </div>
                <div className='offcanvas-body'>
                    {
                        stepDetail.sessions ?
                        <>
                        {
                            stepDetail.sessions.map((session, idx) =>
                            <>
                            <div key={idx} className='pb-3'>
                            <p className='fw-bold font110 border-bottom'>{session.title}</p>
                            {
                                session.goals.length > 0 ?
                                <>
                                {
                                    session.goals.map((item, index) =>
                                    <>
                                    <div key={index} className='border rounded mb-2'>
                                        <div className='p-3 pb-0'>
                                            <p className='fw-bold mb-0'>{index+1}.</p>
                                            <span className='text-green fw-bold fs-6'>{item.title}</span>
                                            <br/>
                                            <span dangerouslySetInnerHTML={{ __html: item.metric }}></span>
                                        </div>
                                        <table className='table table-sm mb-0 border-top'>
                                            <thead className='bg-green-light'>
                                                <tr>
                                                    <th className='text-center'>
                                                        Tipo
                                                    </th>
                                                    <th className='text-center'>
                                                        Período
                                                    </th>
                                                    <th className='text-center'>
                                                        Status
                                                    </th>
                                                    <th className='text-center'>
                                                        Relacionado a
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="text-center">
                                                        <Chip label={item.goal_type_display} />
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        {moment(item.start_date).format("DD-MMM-YYYY")} - {moment(item.end_date).format("DD-MMM-YYYY")} 
                                                    </td>
                                                    <td className="align-middle text-center">
                                                    {
                                                        item.goalLastStatus && item.goalLastStatus.status ?
                                                        <>
                                                        {
                                                            item.goalLastStatus.status === 'CS' ?
                                                            <>
                                                            <FontAwesomeIcon className='text-green' icon={solid('circle-check')} /> {item.goalLastStatus.status_display}
                                                            </>
                                                            :
                                                            <>{item.goalLastStatus.status_display}</>
                                                        }
                                                        </>
                                                        :
                                                        <>Não Iniciado</>
                                                    }
                                                    </td>
                                                    <td className="align-middle text-center">
                                                    {
                                                        item.related_goal ?
                                                        <>
                                                        {item.related_goal.title}
                                                        </>
                                                        :
                                                        <>-</>
                                                    }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className='p-3 bg-light'>
                                            <p className='fw-bold font110 border-bottom'>Avaliação</p>
                                            <div className=''>
                                                {
                                                    item.itemStatus && item.itemStatus.length > 0  ?
                                                    <>
                                                    {
                                                        item.itemStatus.map((sts, index) =>
                                                        <>
                                                        <FormCreateItemStatus stepId={stepDetail.id} objectStatus={stepDetail.status} object={sts} objectType={item.session_type} itemType={stepDetail.step_type} employeeId={stepDetail.employee.id} assessmentId={stepDetail.performance.id} sessionId={stepDetail.id} objectId={item.id} />
                                                        </>
                                                        )
                                                    }
                                                    </>
                                                    : null
                                                }
                                                
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                )
                                }
                                </>
                                : null
                            }
                            {
                                session.competencies.length > 0 ?
                                <>
                                {
                                    session.competencies.map((item, index) =>
                                    <>
                                    <div key={index} className='border rounded mb-2'>
                                        <div className='p-3 pb-0'>
                                            <p className='fw-bold mb-0'>{index+1}.</p>
                                            <span className='text-green fw-bold fs-6'>{item.competency.title}</span>
                                            <br/>
                                            <span dangerouslySetInnerHTML={{ __html: item.description }}></span>
                                        </div>
                                        <div className='p-3 bg-light'>
                                            <p className='fw-bold font110 border-bottom'>Avaliação</p>
                                            <div className=''>
                                                {
                                                    item.itemStatus && item.itemStatus.length > 0  ?
                                                    <>
                                                    {
                                                        item.itemStatus.map((sts, index) =>
                                                        <>
                                                        <FormCreateItemStatus stepId={stepDetail.id} objectStatus={stepDetail.status} object={sts} objectType={item.session_type} itemType={stepDetail.step_type} employeeId={stepDetail.employee.id} assessmentId={stepDetail.performance.id} sessionId={stepDetail.id} objectId={item.id} />
                                                        </>
                                                        )
                                                    }
                                                    </>
                                                    : null
                                                }
                                                
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                )
                                }
                                </>
                                : null
                            }
                            {
                                session.questions.length > 0 ?
                                <>
                                {
                                    session.questions.map((item, index) =>
                                    <>
                                    <div key={index} className='border rounded mb-2'>
                                        <div className='p-3 pb-0'>
                                            <p className='fw-bold mb-0'>{index+1}.</p>
                                            <span dangerouslySetInnerHTML={{ __html: item.question }}></span>
                                        </div>
                                        <div className='p-3 bg-light'>
                                            <div className=''>
                                                {
                                                    item.itemStatus && item.itemStatus.length > 0  ?
                                                    <>
                                                    {
                                                        item.itemStatus.map((sts, index) =>
                                                        <>
                                                        <div className="my-1">
                                                            {
                                                                sts.employee_comment ?
                                                                <Editor
                                                                    className='noToolbar'
                                                                    name="employee_comment"
                                                                    style={{ height: '80px' }}
                                                                    headerTemplate={headerEditor}
                                                                    readOnly
                                                                    value={sts.employee_comment}
                                                                />
                                                                :
                                                                <>
                                                                {
                                                                    sts.manager_comment ?
                                                                    <>
                                                                    <Editor
                                                                        className='noToolbar'
                                                                        name="manager_comment"
                                                                        style={{ height: '80px' }}
                                                                        headerTemplate={headerEditor}
                                                                        readOnly
                                                                        value={sts.manager_comment}
                                                                    />
                                                                    </>
                                                                    :
                                                                    <>-</>
                                                                }
                                                                </>
                                                            }
                                                            
                                                        </div>
                                                        </>
                                                        )
                                                    }
                                                    </>
                                                    : null
                                                }
                                                
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                )
                                }
                                </>
                                : null
                            }
                            </div>
                            </>
                            )
                        }
                        </>
                        : null
                    }
                    {
                        stepDetail.performanceStatus ?
                        <>
                        <p className="form-label fw-bold">Comentário Geral</p>
                        <div className='bg-light p-3 border rounded'>
                            <div className="my-1">
                                {
                                    stepDetail.performanceStatus.employee_comment ?
                                    <Editor
                                        className='noToolbar'
                                        name="employee_comment"
                                        style={{ height: '80px' }}
                                        headerTemplate={headerEditor}
                                        readOnly
                                        value={stepDetail.performanceStatus.employee_comment}
                                    />
                                    :
                                    <>
                                    {
                                        stepDetail.performanceStatus.manager_comment ?
                                        <>
                                        <Editor
                                            className='noToolbar'
                                            name="manager_comment"
                                            style={{ height: '80px' }}
                                            headerTemplate={headerEditor}
                                            readOnly
                                            value={stepDetail.performanceStatus.manager_comment}
                                        />
                                        </>
                                        :
                                        <>-</>
                                    }
                                    </>
                                }
                            </div>
                        </div>
                        </>
                        : null
                    }
                </div>
            </div>  
            </>
            : null
        }
        
        </>

    )
}