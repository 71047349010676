import React, {useState, useEffect, useRef} from 'react';
import ProtectedRoute from '../../routes/protected';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import performanceService from '../../services/data/performance.service';
import moment from "moment";
import 'moment/locale/pt-br'
import { Toast } from 'primereact/toast';
import SkeletonTeam from './skeletonTeam';
import { useFormik } from 'formik';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle'


export default function PerformanceList() {

    const [pageLoad, setPageLoad] = useState(true)
    const [filterLoad, setFilterLoad] = useState(false)
    const toast = useRef(null);
    const [itemsList, setItemsList] = useState([]);
    const [page, setPage] = useState(1)
    const [clear, setClear] = useState(false)
    const [selected, setSelected] = useState('')

    const canvaRef = useRef()

    const hideOffCanvas = () => {
        const elem = canvaRef.current
        const modal = bootstrap.Offcanvas.getInstance(elem)
        modal.hide()
    }

    useEffect(() => {
        listItems()
    }, [page])

    function listItems() {
        let data = {
            company_id: localStorage.getItem("companyId"),
            created_by: true,
        }

        performanceService.listPerformanceReview(page, data).then((response) => {
            if (response.count > 0){
                setItemsList(response)
            } else {
                toast.current.show({ severity: 'warn', summary: 'Ops', detail: "Nenhum item encontrado", life: 4000 });
            }
        }).catch((error) => {
            toast.current.show({ severity: 'warn', summary: 'Ops', detail: JSON.stringify(error.response.data), life: 4000 });
        }).finally(() => {
            setPageLoad(false)
        })
    }

    function addPage() {
        setPage(page + 1)
    }

    function subPage() {
        setPage(page - 1)
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            title: '',
            start_date: '',
            end_date: ''
        },
        onSubmit: values => {

            setFilterLoad(true)
            setClear(true)

            let data = {
                company_id: localStorage.getItem("companyId"),
                created_by: true
            }
            if (values.title) {
                data.title = values.title
            }
            if (values.start_date) {
                data.start_date = values.start_date
            }
            if (values.end_date) {
                data.end_date = values.end_date
            }

            performanceService.listPerformanceReview(1, data).then((response) => {    
                if (response.count > 0){
                    setItemsList(response)
                } else {
                    toast.current.show({ severity: 'warn', summary: 'Ops', detail: "Nenhum item encontrado", life: 4000 });
                }
            }).catch((error) => {
                toast.current.show({ severity: 'warn', summary: 'Ops', detail: JSON.stringify(error.response.data), life: 4000 });
            }).finally(() => {
                setFilterLoad(false)
            })
        }
    })


    return (
        <ProtectedRoute>
            
            <Toast ref={toast} position="top-right" />

            <div className="container-fluid">
                <div className='row flex-nowrap'>
                    <div className='col text-bg-green p-3'>
                        <a href='/painel/' className="fs-6 fw-bold ps-5 text-decoration-none text-white">
                            <FontAwesomeIcon className='' icon={solid('arrow-left')} /> Performance
                        </a>
                    </div>
                </div>
                <div className='my-4 mx-5 py-3 rounded-top' style={{minHeight: '500px'}}>

                    {
                        pageLoad ?
                        <SkeletonTeam />
                        :
                        <>
                        <ul className='nav nav-tabs px-3'>                            
                            <li className='nav-item'>
                                <a href='/my/team/performance/' className='nav-link text-green'>
                                    Minha Equipe
                                </a>
                            </li>
                            <li className='nav-item'>
                                <a href='/my/team/performance/matrix/' className='nav-link text-green'>
                                    Matriz
                                </a>
                            </li>
                            <li className='nav-item'>
                                <span className='nav-link active'>
                                    Lista de Avaliações
                                </span>
                            </li>
                        </ul>
                        <div className='bg-white py-4 px-3 rounded'>
                            <div className='row'>
                                <div className='col-3'>
                                    <p className='fw-bold mt-3'>Filtros</p>
                                    <form onSubmit={formik.handleSubmit} className='bg-light p-3 rounded'>
                                        <div className="col mb-3">
                                            <label className="form-label">Título</label>
                                            <input name='title' type="text" className="form-control" {...formik.getFieldProps('title')} />
                                        </div>
                                        <div className='col mb-3'>
                                            <label className="form-label">De</label>
                                            <input className="form-control" name='start_date' type="date" autoComplete="off" {...formik.getFieldProps('start_date')} />
                                        </div>
                                        <div className='col mb-3'>
                                            <label className="form-label">Até</label>
                                            <input className="form-control" name='end_date' type="date" autoComplete="off" {...formik.getFieldProps('end_date')} />
                                        </div>
                                        <div className="btnMargin">
                                            <button className="btn btn-success btn-sm btn-rounded px-4" type="submit">
                                                Filtrar
                                            </button>
                                            {
                                                clear ?
                                                <span role='button' className='fw-bold float-end mt-2' onClick={() => {{formik.resetForm(); listItems()}}}>Limpar</span>
                                                :null
                                            }
                                            
                                        </div>
                                    </form>
                                </div>
                                <div className='col-9'>
                                    {
                                        filterLoad ?
                                        <SkeletonTeam />
                                        :
                                        <>
                                        {
                                            itemsList && itemsList.results && itemsList.results.length > 0 ?
                                            <>
                                            <table className='table table-borderless thover text-default borderBottom mt-3'>
                                                <thead className='borderBottom'>
                                                    <tr>
                                                        <th>
                                                            Avaliação
                                                        </th>
                                                        <th className='text-center'>
                                                            Ciclo
                                                        </th>
                                                        <th className='text-center'>
                                                            Template
                                                        </th>
                                                        <th className='text-center'>
                                                            Período
                                                        </th>
                                                        <th className='text-center'>
                                                            Status
                                                        </th>
                                                        <th className='text-center'></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    itemsList.results.map((item, index) =>
                                                        <tr key={index}>
                                                            <td className="align-middle">
                                                                <a href={`/performance-review/${item.id}/process/`} className='text-green fw-bold text-decoration-none'>
                                                                    {item.title}
                                                                </a>
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {
                                                                    item.template ?
                                                                    <a href={`/performance-template/${item.template.id}/process/`} className='text-green fw-bold text-decoration-none'>
                                                                        {item.template.title}
                                                                    </a>
                                                                    : <>-</>
                                                                }
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {item.assessmentTypeDisplay}
                                                            </td>
                                                            <td className="align-middle text-center">
                                                            {moment(item.start_date).format("DD-MMM-YYYY")} - {moment(item.end_date).format("DD-MMM-YYYY")} 
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {item.statusDisplay}
                                                            </td>
                                                            {/* 
                                                            
                                                            <td className="align-middle text-center">
                                                                <div className='dropdown'>
                                                                    <button className='btn btn-sm rounded-circle border dropdown-toggle no-caret' data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <FontAwesomeIcon className='text-green' icon={solid('ellipsis')} />
                                                                    </button>
                                                                    <ul className='dropdown-menu border headerBorder font1em'>
                                                                        {
                                                                            item.status === 'AT'?
                                                                            <li>
                                                                                <button className="dropdown-item text-default">
                                                                                    Editar
                                                                                </button>
                                                                            </li>
                                                                            : null
                                                                        }
                                                                        <li>
                                                                            <button className="dropdown-item text-default" data-bs-toggle="offcanvas" data-bs-target="#offCanvasClone" onClick={() => setSelected(item)}>
                                                                                Clonar
                                                                            </button>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </td>
                                                            
                                                            */}
                                                            
                                                        </tr>
                                                    )
                                                }
                                                </tbody>
                                            </table>
                                            <div className='float-end'>
                                                <nav>
                                                    <ul className='pagination pagination-sm'>
                                                        <li className='page-item'>
                                                            { 
                                                                itemsList.previous ? 
                                                                <span onClick={subPage} role="button" className='page-link text-default'>
                                                                    <FontAwesomeIcon className='' icon={solid('angle-left')} />
                                                                </span>
                                                                :
                                                                <span className='page-link text-grey disabled'>
                                                                    <FontAwesomeIcon className='' icon={solid('angle-left')} />
                                                                </span>
                                                            }
                                                        </li>
                                                        <li className='page-item'>
                                                            {
                                                                itemsList.next ?
                                                                <span onClick={addPage} role="button" className='page-link text-default'>
                                                                    <FontAwesomeIcon className='' icon={solid('angle-right')} />
                                                                </span>
                                                                :
                                                                <span className='page-link text-grey disabled'>
                                                                    <FontAwesomeIcon className='' icon={solid('angle-right')} />
                                                                </span>
                                                            }
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                            </>

                                            :
                                            <>
                                            Nenhum item criado ainda
                                            </>
                                        }
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        </>
                    }
                </div>
            </div>

            {/* 
            <div ref={canvaRef} className='offcanvas offcanvas-end offcanvas-size-xl' data-bs-backdrop="static" id='offCanvasClone' tabIndex={-1}>
                <div className='offcanvas-header border-bottom'>
                    <h6 className='offcanvas-title'>Clonar</h6>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className='offcanvas-body'>
                    {
                        selected?
                        <>
                        <p className='lead'>
                            Tem certeza de que deseja clonar {selected.title} ?
                        </p>
                        <p>
                            <a href={`/performance-review/${selected.id}/clone/`} className='btn btn-sm btn-success btn-rounded px-3' >Iniciar Processo</a>
                        </p>
                        </>
                        : null
                    }
                </div>
            </div>
            */}

            
        </ProtectedRoute>
    )
}