import React, {useState, useEffect} from 'react'

export default function TypeSteps(props) {

    const [type, setType] = useState('')

    useEffect(() => {

        if(props.type) {
            setType(props.type)
        }

    }, [props])

    return (
        <div>
            {
                type ?
                <>
                {
                    type !== 'PD' ? // PDI
                    <>
                    <p className='fw-bold mb-1'>Etapas:</p>
                    {
                        type === 'RD'? //1:1
                        <>
                        <ol>
                            <li>Reunião 1:1</li>
                        </ol>
                        </>
                        : null
                    }
                    {
                        type === 'BD'? //90
                        <>
                        <ol>
                            <li>Avaliação do Gestor</li>
                            <li>Reunião 1:1</li>
                        </ol>
                        </>
                        : null
                    }
                    {
                        type === 'AD'? //180
                        <>
                        <ol>
                            <li>Auto Avaliação</li>
                            <li>Avaliação do Gestor</li>
                            <li>Reunião 1:1</li>
                        </ol>
                        </>
                        : null
                    }
                    {
                        type === 'SD'? //270
                        <>
                        <ol>
                            <li>Auto Avaliação</li>
                            <li>Avaliar Superior</li>
                            <li>Avaliação do Gestor</li>
                            <li>Reunião 1:1</li>
                        </ol>
                        </>
                        : null
                    }
                    {
                        type === 'TD'? //360
                        <>
                        <ol>
                            <li>Auto Avaliação</li>
                            <li>Avaliar Superior</li>
                            <li>Avaliação do Gestor</li>
                            <li>Avaliação dos Pares</li>
                            <li>Reunião 1:1</li>
                        </ol>
                        </>
                        : null
                    }
                    </>
                    : null
                }
                </>
                : null
            }
        </div>
    )

}