import React, {useState, useEffect, useRef, useMemo} from 'react';
import ProtectedRoute from '../../routes/protected';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Avatar } from 'primereact/avatar';
import NameInitials from '../../helpers/nameInitials';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';
import userData from '../../context/user';
import careerService from '../../services/data/career.service';
import UsePerms from '../../context/userAccess';
import SkeletonSummary from './skeletonSummary';
import CareerHeader from './careerHeader';
import CareerMenu from './careerMenu';


export default function CareerSummary(props) {

    const [pageLoad, setPageLoad] = useState(true)
    const {user} = userData()
    const toast = useRef(null);
    const navigate = useNavigate();
    const [userEmployee, setUserEmployee] = useState('')
    const VerifyPerms = UsePerms()
    const [showPerformanceTabs, setShowPerformanceTabs] = useState(false)


    useEffect(() => {

        getData()
        
    }, [])

    function getData() {

        let data = {
            company_id: localStorage.getItem("companyId"),
        }

        if (props.id) {
            data.id = props.id
        }

        careerService.getEmployeeSummary(data).then((response) => {    
            setUserEmployee(response)
        }).catch((error) => {
            console.log(error.response)
        }).finally(() => {
            setPageLoad(false)
        })
    
    }

    useMemo(() => {
        // if user is superior, show performance tabs
        if (userEmployee && userEmployee.manager && userEmployee.manager.uid === user.uid) {
            setShowPerformanceTabs(true)
        }
        // if its the user itself, show performance tabs
        if (userEmployee && userEmployee.user.id === user.uid) {
            setShowPerformanceTabs(true)
        }
    }, [userEmployee, user])

    return (
        <ProtectedRoute>
            
            <Toast ref={toast} position="top-right" />

            <div className="container-fluid">

                <CareerHeader Employee={userEmployee} />
                
                <div className='my-4 mx-5 py-3 rounded-top' style={{minHeight: '500px'}}>

                    <CareerMenu EmployeeId={userEmployee.id} showPerformanceTabs={showPerformanceTabs} Page='summary' />

                    <div className='bg-white py-4 rounded'>

                        {
                            pageLoad ?
                            <SkeletonSummary />
                            :
                            <>
                            <div className='row'>

                                <div className='col'>
                                    <div className='p-2 m-1'>
                                        <table className='table font1em table-borderless'>
                                            <thead>
                                                <tr className='border-bottom'>
                                                    <th className='font110' colSpan={2}>
                                                        Seu Cargo Atual
                                                        {
                                                            VerifyPerms(['change_jobposition']) && userEmployee ?
                                                            <a href={`/settings/users-management/${userEmployee.id}/edit/`} className='btn btn-link border btn-sm btn-rounded text-green ms-2'>
                                                                <FontAwesomeIcon className='font095' icon={solid('edit')} />
                                                            </a>
                                                            : null
                                                        }
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className='fw-semibold'>Matrícula</td>
                                                    <td>{ userEmployee.employeeId ? userEmployee.employeeId : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td className='fw-semibold'>Organização</td>
                                                    <td>
                                                    { userEmployee.jobposition ? userEmployee.jobposition.subcompany.title : "-"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='fw-semibold'>Cargo</td>
                                                    <td>
                                                    { userEmployee.jobposition ? userEmployee.jobposition.title : "-"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='fw-semibold'>Departamento</td>
                                                    <td>
                                                    { userEmployee.jobposition ? userEmployee.jobposition.department : "-"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='fw-semibold'>Centro de Custo</td>
                                                    <td>
                                                    { userEmployee.jobposition ? userEmployee.jobposition.costcenter : "-"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='fw-semibold'>Localidade</td>
                                                    <td>
                                                    { 
                                                        userEmployee.jobposition ? 
                                                        <>
                                                            <FontAwesomeIcon className='font095' icon={solid('location-dot')} /> {userEmployee.jobposition.subcompany.address}
                                                        </>
                                                        
                                                        : "-"
                                                    }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='fw-semibold'>Data Início</td>
                                                    <td>***</td>
                                                </tr>
                                                <tr>
                                                    <td className='fw-semibold'>Tempo de Empresa</td>
                                                    <td>***</td>
                                                </tr>
                                                <tr>
                                                    <td className='fw-semibold'>Tempo no Cargo</td>
                                                    <td>***</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='p-2 m-1'>
                                        <table className='table font1em table-borderless'>
                                            <thead>
                                                <tr className='border-bottom'>
                                                    <th className='font110' colSpan={2}>
                                                        Infos de Contato (público)
                                                        {
                                                            userEmployee ?
                                                            <>
                                                            {
                                                                user.uid === userEmployee.user.id ?
                                                                <a href='/settings/edit-user/' className='btn btn-link border btn-sm btn-rounded text-green ms-2'>
                                                                    <FontAwesomeIcon className='font095' icon={solid('edit')} />
                                                                </a>
                                                                : null
                                                            }
                                                            </>
                                                            : null
                                                        }
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className='fw-semibold'>Telefone</td>
                                                    <td>
                                                    {
                                                        userEmployee ?
                                                        <>
                                                        <div>
                                                            {userEmployee.user.phone ? userEmployee.user.phone : "-"}
                                                        </div>
                                                        {
                                                            userEmployee.user.phone2 ? 
                                                            <div>{userEmployee.user.phone2}</div>
                                                            : 
                                                            null
                                                        }
                                                        </>
                                                        :
                                                        null
                                                    }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='fw-semibold'>Email</td>
                                                    <td>
                                                    {
                                                        userEmployee ?
                                                        <>
                                                        <div>
                                                            {userEmployee.user.email ? userEmployee.user.email : "-"}
                                                        </div>
                                                        {
                                                            userEmployee.user.email2 ? 
                                                            <div>{userEmployee.user.email2}</div>
                                                            : 
                                                            null
                                                        }
                                                        </>
                                                        :
                                                        null
                                                    }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    {
                                        userEmployee && userEmployee.manager ?
                                        <>
                                        <div className='p-2 m-1'>
                                            <table className='table font1em table-borderless'>
                                                <thead>
                                                    <tr className='border-bottom'>
                                                        <th className='font110' colSpan={2}>Gestor Imediato</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td width={20}>
                                                        {
                                                            userEmployee.manager.photo ?
                                                            <Avatar image={userEmployee.manager.photo} style={{ backgroundColor: '#8463a9', color: '#ffffff', fontWeight: 'bold' }} shape='circle' />
                                                            :
                                                            <Avatar label={NameInitials( userEmployee.manager.name )} style={{ backgroundColor: '#8463a9', color: '#ffffff', fontWeight: 'bold' }} shape='circle' />
                                                        }
                                                        </td>
                                                        <td>
                                                            <div className='text-green font110 fw-bold' onClick={()=>window.open(`/career/summary/${userEmployee.manager.id}/`,'_self', 'rel=noopener noreferrer')} role='button'>
                                                                {userEmployee.manager.name}
                                                            </div>
                                                            {
                                                                userEmployee.manager.email?
                                                                <div>
                                                                <FontAwesomeIcon className='font095 me-1' icon={solid('envelope')} /> {userEmployee.manager.email}
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        </>
                                        : null
                                    }
                                </div>
                                {
                                    userEmployee && userEmployee.team.length > 0 ?
                                    <>
                                    <div className='col'>
                                        <div className='p-2 rounded border m-1'>
                                            <table className='table font1em px-1'>
                                                <thead>
                                                    <tr className='border-bottom'>
                                                        <th className='font110' colSpan={2}>
                                                            {
                                                                userEmployee ?
                                                                <>
                                                                {
                                                                    user.uid === userEmployee.user.id ?
                                                                    <>
                                                                    Minha Equipe
                                                                    </>
                                                                    :
                                                                    <>
                                                                    Equipe
                                                                    </>
                                                                }
                                                                </>
                                                                : null
                                                            }
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    userEmployee.team.map((item, index) =>
                                                    <tr key={index}>
                                                        <td width={20} className='border-bottom-0'>
                                                        {
                                                            item.photo ?
                                                            <Avatar image={item.photo} style={{ backgroundColor: '#57c0fb', color: '#ffffff', fontWeight: 'bold' }} shape='circle' />
                                                            :
                                                            <Avatar label={NameInitials( item.name )} style={{ backgroundColor: '#57c0fb', color: '#ffffff', fontWeight: 'bold' }} shape='circle' />
                                                        }
                                                        </td>
                                                        <td className='border-bottom-0'>
                                                            <div className='text-green fw-bold'>
                                                                <span onClick={()=>window.open(`/career/summary/${item.id}/`,'_self', 'rel=noopener noreferrer')} role='button' >
                                                                    {item.name}
                                                                </span>
                                                            </div>
                                                            <div>{item.jobposition.title}</div>
                                                        </td>
                                                    </tr>
                                                    )
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    </>
                                    : null
                                }
                            </div>
                            </>
                        }
                        
                    </div>
                </div>
            </div>
        </ProtectedRoute>
    )
}