import React, {useState, useRef} from 'react';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import performanceService from '../../../services/data/performance.service';
import * as Yup from 'yup';

import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';


export default function FormCreateDevPlan(props) {
    
    const toast = useRef(null);

    const [title, setTitle] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [status, setStatus] = useState('AT')
    const employee = props.employee

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const load = () => {
        setLoading(true);
        formik.submitForm()
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            title: title,
            start_date: startDate,
            end_date: endDate,
            status: status,
            assessment_type: 'PD',
        },
        validationSchema: Yup.object({
            title: Yup.string().required("Favor preencher o campo Título"),
            start_date: Yup.date().required("Favor preencher o campo Data de Início e de Término"),
            end_date: Yup.date().min(Yup.ref("start_date"), "Data de término tem de ser maior que a data de início"),
        }),
        onSubmit: values => {

            let data = {
                company_id: localStorage.getItem("companyId"),
                title: values.title,
                start_date: values.start_date,
                end_date: values.end_date,
                status: values.status,
                assessment_type: 'PD',
                employee: [{ value: employee.id }],
                result_to_matrix: false
            }

            performanceService.createPerformanceReview(data).then((response) => {
    
                toast.current.show({ severity: 'success', summary: 'Sucesso', detail: `${response.title} criado com sucesso`, life: 5000 });
                
                setTimeout(() => {
                    setLoading(false);
                    return navigate(`/performance-review/${response.id}/process/`)
                }, 3000);

                
            }).catch((error) => {
                toast.current.show({ severity: 'warn', summary: 'Ops', detail: JSON.stringify(error.response.data), life: 6000 });
                setLoading(false);
            });

            
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>

            <Toast ref={toast} position="top-right" />
            <p className='mb-0'>Enviando para: <span className='fw-bold'>{employee.user.name}</span></p>
            <p>Template: <span className='fw-bold'>Plano de Desenvolvimento</span></p>
            <div className="col mb-3">
                <label className="form-label fw-bold">Título <span className="text-danger">*</span></label>
                <input name='title' type="text" className="form-control text-capitalize" required {...formik.getFieldProps('title')} />
                {formik.errors.title ? (<span name="title" className="text-vermelho">{formik.errors.title}</span>) : null}
            </div>

            <div className='row mb-4 px-0'>
                <div className='col'>
                    <label className="form-label fw-bold">Data de Início <span className="text-danger">*</span></label>
                    <input className="form-control" name='start_date' type="date" required="required" autoComplete="off" {...formik.getFieldProps('start_date')} />
                    {formik.errors.start_date ? (<span name="start_date" className="text-vermelho">{formik.errors.start_date}</span>) : null}
                </div>

                <div className='col'>
                    <label className="form-label fw-bold">Data de Término <span className="text-danger">*</span></label>
                    <input className="form-control" name='end_date' type="date" required="required" autoComplete="off" {...formik.getFieldProps('end_date')} />
                    {formik.errors.end_date ? (<span name="end_date" className="text-vermelho">{formik.errors.end_date}</span>) : null}
                </div>
            </div>

            <div className="d-grid mx-auto btnMargin">
                <Button rounded label="Criar" type='submit' icon="pi pi-check" loading={loading} onClick={load} />
            </div>
        </form>
    )
}