
export default function ratingPerformance(val) {
    const lista = {
        'IN' : 1,
        'BE' : 2,
        'DE' : 3,
        'AE' : 4,
        'SE' : 5
    }
    return lista[val]
}