import http from "../http"

class PerformanceService {

    async listGoalPlans(page, data) {
        const rqst = await http.post('goalplan/list/?page=' + page, data)
        return rqst.data
    }

    async createGoalPlan(data) {
        const rqst = await http.post('goalplan/create/', data)
        return rqst.data
    }

    async updateGoalPlan(data) {
        const rqst = await http.post('goalplan/update/', data)
        return rqst.data
    }

    async getGoalPlan(id) {
        let data = {
            company_id: localStorage.getItem("companyId"),
            id: id
        }
        const rqst = await http.post('/goalplan/get/', data)
        return rqst.data
    }

    async deleteGoalPlan(id) {
        let data = {
            company_id: localStorage.getItem("companyId"),
            id: id
        }
        const rqst = await http.post('/goalplan/delete/', data)
        return rqst.data
    }

    async listGoals(page, data) {
        const rqst = await http.post('goal/list/?page=' + page, data)
        return rqst.data
    }

    async listGoalsByCategory(items) {

        let data = {
            company_id: localStorage.getItem("companyId"),
        }

        if (items) {
            data = {...data, ...items}
        }

        const rqst = await http.post('goal/list/bycategory/', data)
        return rqst.data
    }

    async createGoal(data) {
        const rqst = await http.post('goal/create/', data)
        return rqst.data
    }

    async updateGoal(data) {
        const rqst = await http.post('goal/update/', data)
        return rqst.data
    }

    async getGoal(id) {
        let data = {
            company_id: localStorage.getItem("companyId"),
            id: id
        }
        const rqst = await http.post('goal/get/', data)
        return rqst.data
    }

    async deleteGoal(id) {
        let data = {
            company_id: localStorage.getItem("companyId"),
            id: id
        }
        const rqst = await http.post('goal/delete/', data)
        return rqst.data
    }

    async createGoalStatus(data) {
        const rqst = await http.post('goal/status/create/', data)
        return rqst.data
    }

    async listGoalStatus(page, data) {
        const rqst = await http.post('goal/status/list/?page=' + page, data)
        return rqst.data
    }

    async createPerformanceReview(data) {
        const rqst = await http.post('performance/review/create/', data)
        return rqst.data
    }

    async getPerformanceReview(id) {
        let data = {
            company_id: localStorage.getItem("companyId"),
            id: id
        }
        const rqst = await http.post('performance/review/get/', data)
        return rqst.data
    }

    async listPerformanceReview(page, data) {
        const rqst = await http.post('performance/review/list/?page=' + page, data)
        return rqst.data
    }

    async listPerformanceReviewEmployee(page, data) {
        const rqst = await http.post('performance/review/employee/list/?page=' + page, data)
        return rqst.data
    }

    async updatePerformanceReview(data) {
        const rqst = await http.post('performance/review/update/', data)
        return rqst.data
    }

    async deletePerformanceReview(id) {
        let data = {
            company_id: localStorage.getItem("companyId"),
            id: id
        }
        const rqst = await http.post('performance/review/delete/', data)
        return rqst.data
    }

    async createJobPerformance(data) {
        const rqst = await http.post('job-performance/create/', data)
        return rqst.data
    }

    async updateJobPerformance(data) {
        const rqst = await http.post('job-performance/update/', data)
        return rqst.data
    }

    async deleteJobPerformance(data) {
        const rqst = await http.post('job-performance/delete/', data)
        return rqst.data
    }

    async createAssessmentQuestion(data) {
        const rqst = await http.post('assessment-question/create/', data)
        return rqst.data
    }

    async updateAssessmentQuestion(data) {
        const rqst = await http.post('assessment-question/update/', data)
        return rqst.data
    }

    async deleteAssessmentQuestion(data) {
        const rqst = await http.post('assessment-question/delete/', data)
        return rqst.data
    }

    async myTeamPerformance(page, data) {
        const rqst = await http.post('performance/my/team/view/?page=' + page, data)
        return rqst.data
    }
    async myTeamPerformanceChart(data) {
        const rqst = await http.post('performance/team/chart/', data)
        return rqst.data
    }
    async messageTakePerformanceReview(data) {
        const rqst = await http.post('performance/review/message/', data)
        return rqst.data
    }
    async takePerformanceReview(data) {
        const rqst = await http.post('performance/review/take/', data)
        return rqst.data
    }
    async takePerformanceReviewSummary(data) {
        const rqst = await http.post('performance/review/take/summary/', data)
        return rqst.data
    }
    async createItemStatus(data) {
        const rqst = await http.post('performance/review/item-status/create/', data)
        return rqst.data
    }
    async getItemStatus(data) {
        const rqst = await http.post('performance/review/item-status/get/', data)
        return rqst.data
    }
    async updateItemStatus(data) {
        const rqst = await http.post('performance/review/item-status/update/', data)
        return rqst.data
    }
    async createPerformanceStatus(data) {
        const rqst = await http.post('performance/status/create/', data)
        return rqst.data
    }
    async listPerformanceStatus(data) {
        const rqst = await http.post('performance/status/list/', data)
        return rqst.data
    }
    async PerformanceMatrixGet(data) {
        const rqst = await http.post('performance/matrix/get/', data)
        return rqst.data
    }
    async createPerformanceMatrix(data) {
        const rqst = await http.post('performance/matrix/create/', data)
        return rqst.data
    }
    async calibratePerformanceMatrix(data) {
        const rqst = await http.post('performance/matrix/calibrate/', data)
        return rqst.data
    }
    async generalPerformanceMatrixGet(data) {
        const rqst = await http.post('performance/general/matrix/get/', data)
        return rqst.data
    }
    async importCompetencyJobPerformance(data) {
        const rqst = await http.post('performance/import/competencies/', data)
        return rqst.data
    }
    async listAssessmentQuestions(page, data) {
        const rqst = await http.post('assessment-question/list/?page=' + page, data)
        return rqst.data
    }
    async importQuestionJobPerformance(data) {
        const rqst = await http.post('performance/import/questions/', data)
        return rqst.data
    }
    async deletePerformanceSession(data) {
        const rqst = await http.post('performance/session/delete/', data)
        return rqst.data
    }
    async createPerformanceTemplate(data) {
        const rqst = await http.post('performance/template/create/', data)
        return rqst.data
    }
    async updatePerformanceTemplate(data) {
        const rqst = await http.post('performance/template/update/', data)
        return rqst.data
    }
    async getPerformanceTemplate(id) {
        let data = {
            company_id: localStorage.getItem("companyId"),
            id: id
        }
        const rqst = await http.post('performance/template/get/', data)
        return rqst.data
    }
    async addParticipantsPerformanceTemplate(data) {
        const rqst = await http.post('performance/template/add/participants/', data)
        return rqst.data
    }
    async listPerformanceReviewGoals(data) {
        const rqst = await http.post('performance/list/goals/', data)
        return rqst.data
    }
    async importPerformanceReviewGoals(data) {
        const rqst = await http.post('performance/import/goals/', data)
        return rqst.data
    }
    async updatePerformanceTemplateContent(data) {
        const rqst = await http.post('performance/template/update/content/', data)
        return rqst.data
    }
    async createReviewsFromPerformanceTemplate(data) {
        const rqst = await http.post('performance/template/create/reviews/', data)
        return rqst.data
    }
    async updatePerformanceStepSuperior(data) {
        const rqst = await http.post('performance-step/superior/update/', data)
        return rqst.data
    }
    async listEmployeePerformanceSteps(data) {
        const rqst = await http.post('performance/employee/steps/', data)
        return rqst.data
    }
    async performanceStepDetail(data) {
        const rqst = await http.post('performance/step/detail/', data)
        return rqst.data
    }
}

export default new PerformanceService()